import {
  Box,
  Divider,
  Flex,
  Heading,
  HStack,
  Image,
  ListItem,
  Text,
  UnorderedList,
  VStack,
} from "@chakra-ui/react";
import Copyright from "../../../components/Sm/Copyright";
import Grid from "../../../components/Sm/Grid";
import { useConverter } from "../../../hooks/Convert/Convert";

interface IReportForOneTwo {
  item?: any;
}

const ReportFor1_2: React.FC<IReportForOneTwo> = ({ item }) => {
  const pageDoMoreOfList = item?.pageDoMoreOf.split(";");
  const pageLetGoOfList = item?.pageLetGoOf.split(";");
  const pageChallengeList = item?.pageChallenge.split(";");

  return (
    <Box
      h="auto"
      className="page-break"
      overflow="hidden"
      w="full"
      position="relative"
    >
      <HStack align="center" justify="center" w="full" pos="relative">
        <Copyright />
        <Grid text={item?.GNPN} />
      </HStack>
      <Flex w="full" justify="center" align="center" gap={8} mb={6}>
        <Image
          src={`/uploads/${item?.logoOne && item?.logoOne}`}
          w={["14", "20"]}
          h={["14", "20"]}
          alt="image"
        />
        <VStack spacing={[0, 1]}>
          <Divider borderColor="gray.200" />
          <Text fontSize={["14px", "16px"]} fontWeight="bold">
            {item?.pageNumber}
          </Text>
          <Heading
            fontSize={["15px", "18px"]}
            fontWeight="400"
            textTransform="uppercase"
          >
            {item?.pageTitle}
          </Heading>
          <Text fontSize={["13px", "14px"]}>{item?.pageSubtitle}</Text>
          <Divider borderColor="gray.200" />
          <Heading
            fontSize={["17px", "20px"]}
            pt={[2, 3]}
            pb={[1, 2]}
            textTransform="uppercase"
          >
            {item?.pageName}
          </Heading>
        </VStack>
        <Image
          src={`/uploads/${item?.logoTwo}`}
          w={["14", "20"]}
          h={["14", "20"]}
          alt="image"
        />
      </Flex>
      {/* heading */}
      <VStack align="left" justify="center" my={[4, 6]} spacing={2}>
        <Heading
          fontSize={["15px", "17px"]}
          fontWeight="500"
          textTransform="uppercase"
          textAlign="left"
          pb={2}
        >
          soul purpose: {item?.pagePurpose}
        </Heading>
        <Text fontSize={["15px", "16px"]} textAlign="left">
          {item?.purposeDescription}
        </Text>
      </VStack>
      {/* desire */}
      <HStack my={[4, 6]} align="left">
        <Heading textTransform="uppercase" fontWeight="500" fontSize="17px">
          Desire:
        </Heading>
        <Text fontSize={["15px", "16px"]}>{item?.pageDesire}</Text>
      </HStack>
      <Flex my={[4, 6]} gap={[4, 8]} justify="space-between">
        <VStack align="left">
          <Heading
            textTransform="uppercase"
            fontWeight="500"
            fontSize={["15px", "17px"]}
          >
            Do more of
          </Heading>
          <UnorderedList pl={[5, 7]}>
            {pageDoMoreOfList.map((i: any, index: any) => (
              <ListItem key={index}>{i}</ListItem>
            ))}
          </UnorderedList>
        </VStack>
        <VStack align="left">
          <Heading
            textTransform="uppercase"
            fontWeight="500"
            fontSize={["15px", "17px"]}
          >
            Let go of
          </Heading>
          <UnorderedList pl={[5, 7]}>
            {pageLetGoOfList.map((i: any, index: any) => (
              <ListItem key={index}>{i}</ListItem>
            ))}
          </UnorderedList>
        </VStack>
      </Flex>
      {/* challange */}
      <HStack my={[4, 6]} align="left">
        <Heading
          textTransform="uppercase"
          fontWeight="500"
          fontSize={["15px", "17px"]}
        >
          Avoid:
        </Heading>
        <Text fontSize={["15px", "16px"]}>{item?.pageAvoid}</Text>
      </HStack>
      <VStack align="left" my={[4, 7]}>
        <Heading
          textTransform="uppercase"
          fontWeight="500"
          fontSize={["15px", "17px"]}
        >
          Challenges:
        </Heading>
        <UnorderedList pl={[5, 7]}>
          {pageChallengeList.map((i: any, index: any) => (
            <ListItem key={index}>{i}</ListItem>
          ))}
        </UnorderedList>
      </VStack>
      {/* color box */}
      <Box
        w={["10", "14"]}
        h={["10", "14"]}
        // bg="red"
        background={`${item?.box_color}`}
      ></Box>
    </Box>
  );
};

export default ReportFor1_2;
