import {
  Box,
  Flex,
  Heading,
  HStack,
  Image,
  ListItem,
  UnorderedList,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import Copyright from "../../../components/Sm/Copyright";
import Grid from "../../../components/Sm/Grid";
import "../../../styles/index.css";

interface IReport {
  item?: any;
}

const Report7: React.FC<IReport> = ({ item }) => {
  const whenAlignedList = item?.whenAligned.split(";");
  const whenUnalignedList = item?.whenUnaligned.split(";");
  return (
    <Box
      className="page-break"
      // h={["1050px"]}
      h="auto"
      overflow="hidden"
      w="full"
      position="relative"
    >
      <HStack align="center" justify="center" w="full" pos="relative">
        <Copyright />
        <Grid text={item?.GNPN} />
      </HStack>

      <VStack align="left" py={[2, 10]} gap={[1, 5]} mb={["0", 0]}>
        <Flex align="flex-start" justify="space-between">
          <VStack textAlign="left" align="left">
            <HStack spacing={[2, 3]}>
              <Box
                w={["10", "14"]}
                h={["10", "14"]}
                background={`${item?.box_color}`}
              ></Box>
              <Heading fontSize={["16px", "25px"]} textTransform="uppercase">
                {item?.pageTitle}
              </Heading>
            </HStack>
            <Heading
              fontSize={["17px", "20px"]}
              pt={[2, 3]}
              pb={[1, 2]}
              textTransform="uppercase"
            >
              {item?.pageName}
            </Heading>
          </VStack>
          <Image
            w={["24", "20"]}
            h={["24", "20"]}
            // src={`/uploads/${item?.logoOne ? item?.logoOne : item?.logoTwo}`}
            src={`/uploads/${item?.logoTwo}`}
            alt="logo"
          />
        </Flex>
        <VStack
          align="left"
          textAlign="left"
          justify="center"
          my={[2, 7]}
          spacing={0}
        >
          <Heading
            fontSize={["15px", "18px"]}
            fontWeight="500"
            textTransform="uppercase"
            textAlign="left"
            pb={4}
          >
            {item?.pagePurpose}
          </Heading>
          <div
            style={{ width: "100%", marginTop: "5px", marginBottom: "5px" }}
            dangerouslySetInnerHTML={{ __html: item?.pageDescription }}
          ></div>
        </VStack>
        {item?.whenAligned ? (
          <Flex my={[2, 7]} gap={[2, 10]} justify="space-between">
            <VStack align="left" w="50%">
              <Heading
                textTransform="uppercase"
                fontWeight="500"
                textAlign="left"
                fontSize={["14px", "17px"]}
              >
                when aligned:
              </Heading>
              <UnorderedList pl={[5, 7, 8]}>
                {whenAlignedList.map((i: any, index: any) => (
                  <ListItem key={index}>{i}</ListItem>
                ))}
              </UnorderedList>
            </VStack>
            <VStack align="left" w="50%">
              <Heading
                textTransform="uppercase"
                fontWeight="500"
                fontSize={["14px", "17px"]}
                textAlign="left"
              >
                unaligned
              </Heading>
              <UnorderedList pl={[3, 7]}>
                {whenUnalignedList.map((i: any, index: any) => (
                  <ListItem key={index}>{i}</ListItem>
                ))}
              </UnorderedList>
            </VStack>
          </Flex>
        ) : null}
      </VStack>
    </Box>
  );
};

export default Report7;
