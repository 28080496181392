import React from "react";
import { Text } from "@chakra-ui/react";

const Copyright = () => {
  return (
    <>
      <Text
        fontSize={["14px", "16px"]}
        textAlign="center"
        textTransform="uppercase"
        pb={[5, 6]}
      >
        &copy; The bliss academy &#174;
      </Text>
    </>
  );
};

export default Copyright;
