import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Input,
  InputGroup,
  Select,
  Spinner,
  Stack,
  useColorMode,
  VStack,
} from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { toast } from "react-hot-toast";
import { BsArrowLeftCircle } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import useAxios from "../../hooks/useAxios";
const NewUser = () => {
  window.document.title = "SOUL BLUEPRINT ALIGNMENT - Create";
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [username, setUsername] = useState<string>("");
  const [role, setRole] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const axios = useAxios();
  const navigate = useNavigate();
  const { colorMode } = useColorMode();
  const queryClient = useQueryClient();

  const createMutation = useMutation({
    mutationFn: () =>
      axios.post("/api/users", { name, email, username, role, password }),
    onSuccess: () => {
      toast.success("User created!");
      navigate("/dashboard/users");
      queryClient.resetQueries({ queryKey: ["users"] });
    },

    onError(error: any) {
      if (!error?.response) return toast.error("Server error");
      toast.error(error?.response?.data?.message);
    },
  });
  return (
    <Flex
      bg={colorMode === "dark" ? "blackAlpha.300" : "white"}
      rounded="10px"
      boxShadow="0px 4px 6px rgba(94, 73, 155, 0.04)"
      flexDir="column"
      gap={6}
    >
      {/* page header */}
      <Stack
        borderBottom="1px"
        borderBottomColor="#a6aebb4d"
        w="full"
        p={[2, 5]}
      >
        <Heading fontSize="20px" fontWeight="500">
          Create New User
        </Heading>
      </Stack>
      {/* from */}
      <VStack px={[2, 14]} pb={[2, 5]} spacing={[2, 4]}>
        <HStack
          w="full"
          justify="space-between"
          flexDir={["column", "row"]}
          spacing={[2, 5]}
        >
          <Stack w="full">
            <FormControl isRequired>
              <FormLabel fontSize="16px" fontWeight="400">
                Users role
              </FormLabel>
              <Select
                placeholder="Select user role"
                onChange={(e) => setRole(e.target.value)}
              >
                <option value="ADMIN">ADMIN</option>
                <option value="CLIENT">COACH</option>
              </Select>
            </FormControl>
          </Stack>
          <Stack w="full">
            <FormControl>
              <FormLabel fontSize="16px" fontWeight="400">
                Full Name
              </FormLabel>
              <InputGroup>
                <Input
                  size="lg"
                  type="text"
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Full name"
                />
              </InputGroup>
            </FormControl>
          </Stack>
        </HStack>
        <HStack
          w="full"
          justify="space-between"
          flexDir={["column", "row"]}
          spacing={[2, 5]}
        >
          <Stack w="full">
            <FormControl>
              <FormLabel fontSize="16px" fontWeight="400">
                Email (optional)
              </FormLabel>
              <InputGroup>
                <Input
                  onChange={(e) => setEmail(e.target.value)}
                  size="lg"
                  type="text"
                  placeholder="Email"
                />
              </InputGroup>
            </FormControl>
          </Stack>
        </HStack>
        <HStack
          w="full"
          justify="space-between"
          flexDir={["column", "row"]}
          spacing={[2, 5]}
        >
          <Stack w="full">
            <FormControl isRequired>
              <FormLabel fontSize="16px" fontWeight="400">
                Username
              </FormLabel>
              <InputGroup>
                <Input
                  onChange={(e) => setUsername(e.target.value)}
                  size="lg"
                  type="text"
                  placeholder="Username"
                />
              </InputGroup>
            </FormControl>
          </Stack>
          <Stack w="full">
            <FormControl isRequired>
              <FormLabel fontSize="16px" fontWeight="400">
                Password
              </FormLabel>
              <InputGroup>
                <Input
                  onChange={(e) => setPassword(e.target.value)}
                  size="lg"
                  type="text"
                  placeholder="Password"
                />
              </InputGroup>
            </FormControl>
          </Stack>
        </HStack>

        <HStack justify="space-between" w="full" py={5}>
          <Button
            as={Link}
            to="/dashboard/users"
            leftIcon={<BsArrowLeftCircle />}
            fontSize="16px"
            fontWeight="400"
            rounded="6px"
          >
            Back
          </Button>
          <Button
            type="submit"
            fontSize="16px"
            fontWeight="400"
            bg="#FF6633"
            color="white"
            rounded="6px"
            width="130px"
            onClick={() => createMutation.mutate()}
            _hover={{ bg: "#fb4509", transition: "ease 0.3s all" }}
          >
            {createMutation.isLoading ? <Spinner /> : "Create"}
          </Button>
        </HStack>
      </VStack>
    </Flex>
  );
};

export default NewUser;
