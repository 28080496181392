import { combineReducers, configureStore, Reducer } from "@reduxjs/toolkit";
import alphaToNumberSlice from "./slice/alphaToNumberSlice";

const rootReducer: Reducer = combineReducers({
  alphaToNumberSlice,
});

export const store = configureStore({
  reducer: rootReducer,
});
