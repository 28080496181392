import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
} from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { toast } from "react-hot-toast";
import { IUser, Role } from "../../context/AuthContext/AuthContext";
import useAxios from "../../hooks/useAxios";

interface IUserFormProps {
  mode: "EDIT" | "CREATE";
  user?: IUser;
  id?: string | number;
  onOpen?: any;
  isOpen?: any;
  onClose?: any;
}

const UserFrom = ({
  mode,
  user,
  onOpen,
  isOpen,
  onClose,
  id,
}: IUserFormProps) => {
  const queryClient = useQueryClient();
  const axios = useAxios();
  const [role, setRole] = useState<string | Role>(
    mode === "EDIT" && user ? (user.role as Role) : "CLIENT"
  );

  const [name, setName] = useState<string>(
    mode === "EDIT" && user ? user.name : ""
  );
  const [email, setEmail] = useState<string>(
    mode === "EDIT" && user ? user.email : ""
  );

  const [username, setUsername] = useState<string>(
    mode === "EDIT" && user ? user.username : ""
  );
  const [password, setPassword] = useState<string>("");
  const updateSubmit = async (e: any) => {
    const data = {
      role,
      name,
      email,
      username,
      password,
    };
    if (!role || !name || !username || !password || !email)
      return toast.error("Please fill all input");
    try {
      const res = await axios.put(`/api/users/update/${id}`, data);
      queryClient.invalidateQueries({ queryKey: ["users", id] });
      onClose();
      toast.success(res?.data?.message);
    } catch (error) {
      toast.error("Something went wrong!");
    }
  };
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Update user</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl pb="2">
              <FormLabel>Role</FormLabel>
              <Select
                placeholder="Select role"
                value={role}
                onChange={(e) => setRole(e.target.value)}
              >
                <option value="ADMIN">ADMIN</option>
                <option value="CLIENT">COACH</option>
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel>Name</FormLabel>
              <Input
                value={name}
                placeholder="name"
                onChange={(e) => setName(e.target.value)}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Email</FormLabel>
              <Input
                value={email}
                placeholder="name"
                onChange={(e) => setEmail(e.target.value)}
              />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Username</FormLabel>
              <Input
                value={username}
                placeholder="username"
                onChange={(e) => setUsername(e.target.value)}
              />
            </FormControl>
            <FormControl mt={4} isRequired>
              <FormLabel>Password</FormLabel>
              <Input
                value={password}
                placeholder="password"
                onChange={(e) => setPassword(e.target.value)}
              />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={updateSubmit}>
              <Text onClick={updateSubmit}>Update</Text>
            </Button>
            <Button onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default UserFrom;
