import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Input,
  Spinner,
  Text,
  useColorMode,
  VStack,
} from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import chartImage from "../../assets/img/img.jpg";
import GridDataContext from "../../context/Grid/Grid";
import InputContext from "../../context/ReportContext/ReportContext";
import NumberContext from "../../context/SmNumberContext/NumberContext";
import useAxios from "../../hooks/useAxios";
import "./input.css";
import { useSelector } from "react-redux";
import { isMatchNumber } from "../../utils/defualtValue";

const InputNumber = () => {
  window.document.title = "SOUL BLUEPRINT ALIGNMENT - Enter codes";
  const [gridOne, setGridOne] = useState<string>("");
  const [gridTow, setGridTwo] = useState<string>("");
  const [gridThree, setGridThree] = useState<string>("");
  const [gridFour, setGridFour] = useState<string>("");
  const [gridFive, setGridFive] = useState<string>("");
  const [gridSix, setGridSix] = useState<string>("");
  const [gridSeven, setGridSeven] = useState<string>("");
  const [gridEight, setGridEight] = useState<string>("");
  const [gridNine, setGridNine] = useState<string>("");
  const { inputState } = useContext(InputContext);
  // for sm input
  const [numberOne, setNumberOne] = useState<number>(0);
  const [numberTwo, setNumberTwo] = useState<number>(0);
  const [numberThree, setNumberThree] = useState<number>(0);
  const [numberFour, setNumberFour] = useState<number>(0);
  const [numberFive, setNumberFive] = useState<number>(0);
  const [numberSix, setNumberSix] = useState<number>(0);
  const [numberSeven, setNumberSeven] = useState<number>(0);
  const [numberSevenTwo, setNumberSevenTwo] = useState<number>(0);
  const [numberEight, setNumberEight] = useState<number>(0);
  const [numberEightTwo, setNumberEightTwo] = useState<number>(0);
  const [numberNine, setNumberNine] = useState<number>(0);
  const [numberNineTwo, setNumberNineTwo] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const { numberDispatch } = useContext(NumberContext);
  const navigate = useNavigate();
  const axios = useAxios();
  const { colorMode } = useColorMode();
  const alphaNum = useSelector(
    ({ alphaToNumberSlice }: any) => alphaToNumberSlice
  );
  const [result, setResult] = useState<number>(0);
  const { gridDispatch } = useContext(GridDataContext);
  const isChart = inputState?.userInputs?.charts
    ? inputState?.userInputs?.charts
    : "CHART_ONE";

  useEffect(() => {
    if (!inputState?.userInputs) {
      console.log("%c there are no data!", "color: red");
    }
  }, [inputState?.userInputs]);

  // generate report form
  const generate = async () => {
    setLoading(true);

    const data = {
      numberOne,
      numberTwo,
      numberThree,
      numberFour,
      numberFive,
      numberSix,
      numberSeven,
      numberSevenTwo,
      numberEight,
      numberEightTwo,
      numberNine,
      numberNineTwo,
    };
    try {
      const res = await axios.post(`/api/data/get`, {
        gridOne,
        gridThree,
        gridFour,
        gridFive,
        gridSix,
        gridSeven,
        gridEight,
        gridNine,
        chart: isChart,
      });
      const resData = res?.data;
      numberDispatch({ type: "set_number", payload: data });
      gridDispatch({ type: "set_grid", payload: resData });
      navigate("/export");
      setLoading(false);
    } catch (error) {
      toast.error("Report not found!");
      setLoading(false);
    }
  };

  useEffect(() => {
    switch (gridOne) {
      case "G1N1":
        setGridTwo("7");
        break;
      case "G1N2":
        setGridTwo("8");
        break;
      case "G1N3":
        setGridTwo("9");
        break;
      case "G1N4":
        setGridTwo("10");
        break;
      case "G1N5":
        setGridTwo("11");
        break;
      case "G1N6":
        setGridTwo("12");
        break;
      case "G1N7":
        setGridTwo("1");
        break;
      case "G1N8":
        setGridTwo("2");
        break;
      case "G1N9":
        setGridTwo("3");
        break;
      case "G1N10":
        setGridTwo("4");
        break;
      case "G1N11":
        setGridTwo("5");
        break;
      case "G1N12":
        setGridTwo("6");
        break;
    }
  }, [gridOne, setGridOne]);

  useEffect(() => {
    if (alphaNum.name === 0) return setResult(0);
    if (alphaNum.dathOfBirth === 0) return setResult(0);

    const totalValue = alphaNum.name + alphaNum.dathOfBirth;

    if (isMatchNumber.includes(totalValue)) {
      setResult(totalValue);
    } else {
      let sum = 0;
      totalValue
        .toString()
        .split("")
        .forEach((digit: any) => {
          sum += parseInt(digit);
        });

      if (isMatchNumber.includes(sum)) {
        setResult(sum);
      } else if (sum > 9) {
        let newSum = 0;
        sum
          .toString()
          .split("")
          .forEach((digit: any) => {
            newSum += parseInt(digit);
          });
        setResult(newSum);
      } else {
        setResult(sum);
      }
    }
  }, [alphaNum]);
  return (
    <>
      <VStack spacing="0">
        <Heading py={16} textTransform="uppercase">
          Enter codes
        </Heading>
        <Box
          color={colorMode === "dark" ? "white" : "black"}
          sx={{
            backgroundImage: `${chartImage}`,
            width: "400px",
            height: "400px",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
          position="relative"
        >
          <Input
            min="1"
            max="2"
            onChange={(e) => setNumberThree(Number(e.target.value))}
            fontWeight={"bold"}
            fontSize="14px"
            position="absolute"
            textAlign="center"
            border="1px"
            borderColor="black"
            shadow="xl"
            type="number"
            p={0}
            m={0}
            w="27px"
            h="27px"
            borderRadius="full"
            bg="white"
            top={"37px"}
            left={"185px"}
          />
          <Input
            min="1"
            max="2"
            onChange={(e) => setNumberSix(Number(e.target.value))}
            fontWeight={"bold"}
            fontSize="14px"
            position="absolute"
            textAlign="center"
            border="1px"
            borderColor="black"
            shadow="xl"
            type="number"
            p={0}
            m={0}
            w="27px"
            h="27px"
            borderRadius="full"
            bg="white"
            top={"151px"}
            left={"185px"}
          />
          <Input
            min="1"
            max="2"
            onChange={(e) => setNumberFour(Number(e.target.value))}
            fontWeight={"bold"}
            fontSize="14px"
            position="absolute"
            textAlign="center"
            border="1px"
            borderColor="black"
            shadow="xl"
            type="number"
            p={0}
            m={0}
            w="27px"
            h="27px"
            borderRadius="full"
            bg="white"
            top={"151px"}
            left={"112px"}
          />
          <Input
            min="1"
            max="2"
            onChange={(e) => setNumberOne(Number(e.target.value))}
            fontWeight={"bold"}
            fontSize="14px"
            position="absolute"
            textAlign="center"
            border="1px"
            borderColor="black"
            shadow="xl"
            type="number"
            p={0}
            m={0}
            w="27px"
            h="27px"
            borderRadius="full"
            bg="white"
            top={"151px"}
            left={"32px"}
          />
          <Input
            min="1"
            max="2"
            onChange={(e) => setNumberFive(Number(e.target.value))}
            fontWeight={"bold"}
            fontSize="14px"
            position="absolute"
            textAlign="center"
            border="1px"
            borderColor="black"
            shadow="xl"
            type="number"
            p={0}
            m={0}
            w="27px"
            h="27px"
            borderRadius="full"
            bg="white"
            top={"151px"}
            right={"114px"}
          />
          <Input
            min="1"
            max="2"
            onChange={(e) => setNumberTwo(Number(e.target.value))}
            fontWeight={"bold"}
            fontSize="14px"
            position="absolute"
            textAlign="center"
            border="1px"
            borderColor="black"
            shadow="xl"
            type="number"
            p={0}
            m={0}
            w="27px"
            h="27px"
            borderRadius="full"
            bg="white"
            top={"151px"}
            right={"35px"}
          />
          <Input
            min="1"
            max="2"
            onChange={(e) => setNumberSeven(Number(e.target.value))}
            fontWeight={"bold"}
            fontSize="14px"
            position="absolute"
            textAlign="center"
            border="1px"
            borderColor="black"
            shadow="xl"
            type="number"
            p={0}
            m={0}
            w="27px"
            h="27px"
            borderRadius="full"
            bg="white"
            bottom={"133px"}
            right={"235px"}
          />
          <Input
            min="1"
            max="2"
            onChange={(e: any) => setNumberSevenTwo(Number(e.target.value))}
            fontWeight={"bold"}
            fontSize="14px"
            position="absolute"
            textAlign="center"
            border="1px"
            borderColor="black"
            shadow="xl"
            type="number"
            p={0}
            m={0}
            w="27px"
            h="27px"
            borderRadius="full"
            bg="white"
            bottom={"133px"}
            right={"288px"}
          />
          <Input
            min="1"
            max="2"
            onChange={(e) => setNumberEight(Number(e.target.value))}
            fontWeight={"bold"}
            fontSize="14px"
            position="absolute"
            textAlign="center"
            border="1px"
            borderColor="black"
            shadow="xl"
            type="number"
            p={0}
            m={0}
            w="27px"
            h="27px"
            borderRadius="full"
            bg="white"
            bottom={"87px"}
            right={"214px"}
          />
          <Input
            min="1"
            max="2"
            onChange={(e) => setNumberEightTwo(Number(e.target.value))}
            fontWeight={"bold"}
            fontSize="14px"
            position="absolute"
            textAlign="center"
            border="1px"
            borderColor="black"
            shadow="xl"
            type="number"
            p={0}
            m={0}
            w="27px"
            h="27px"
            borderRadius="full"
            bg="white"
            bottom={"87px"}
            right={"162px"}
          />
          <Input
            min="1"
            max="2"
            onChange={(e) => setNumberNine(Number(e.target.value))}
            fontWeight={"bold"}
            fontSize="14px"
            position="absolute"
            textAlign="center"
            border="1px"
            borderColor="black"
            shadow="xl"
            type="number"
            p={0}
            m={0}
            w="27px"
            h="27px"
            borderRadius="full"
            bg="white"
            bottom={"133px"}
            left={"238px"}
          />
          <Input
            min="1"
            max="2"
            onChange={(e) => setNumberNineTwo(Number(e.target.value))}
            fontWeight={"bold"}
            fontSize="14px"
            position="absolute"
            textAlign="center"
            border="1px"
            borderColor="black"
            shadow="xl"
            type="number"
            p={0}
            m={0}
            w="27px"
            h="27px"
            borderRadius="full"
            bg="white"
            bottom={"133px"}
            left={"285px"}
          />

          {/* === top === */}
          {/* gird three */}
          <select
            id="selectId"
            placeholder=" "
            onChange={(e: any) => setGridThree(e.target.value)}
            style={{
              top: "76px",
              left: "187px",
            }}
          >
            <option selected value="">
              --
            </option>
            <option value="G3N1">1</option>
            <option value="G3N2">2</option>
            <option value="G3N3">3</option>
            <option value="G3N4">4</option>
            <option value="G3N5">5</option>
            <option value="G3N6">6</option>
            <option value="G3N7">7</option>
            <option value="G3N8">8</option>
            <option value="G3N9">9</option>
            <option value="G3N10">10</option>
            <option value="G3N11">11</option>
            <option value="G3N12">12</option>
          </select>
          {/* === middle === */}
          {/* grid six */}
          <select
            id="selectId"
            placeholder=" "
            onChange={(e) => setGridSix(e.target.value)}
            style={{ top: "183px", left: "187px" }}
          >
            <option selected value="">
              --
            </option>
            <option value="G6N1">1</option>
            <option value="G6N2">2</option>
            <option value="G6N3">3</option>
            <option value="G6N4">4</option>
            <option value="G6N5">5</option>
            <option value="G6N6">6</option>
            <option value="G6N7">7</option>
            <option value="G6N8">8</option>
            <option value="G6N9">9</option>
            <option value="G6N10">10</option>
            <option value="G6N11">11</option>
            <option value="G6N12">12</option>
          </select>
          {/* grid two */}
          <Input
            placeholder=" "
            disabled
            value={gridTow}
            position="absolute"
            p={0}
            m={0}
            w="20"
            fontSize="lg"
            border="none"
            bg="transparent"
            top="183px"
            right="-24px"
            color={colorMode === "dark" ? "white" : "blackAlpha.900"}
            fontWeight="bold"
            variant="filled"
            _hover={{ bg: "transparent" }}
            outline="none"
            type="text"
          />
          {/* grid one */}
          <select
            id="selectId"
            value={gridOne}
            onChange={(e) => setGridOne(e.target.value)}
            placeholder=" "
            style={{ top: "185px", left: "34px" }}
          >
            <option selected value="">
              --
            </option>
            <option value="G1N1">1</option>
            <option value="G1N2">2</option>
            <option value="G1N3">3</option>
            <option value="G1N4">4</option>
            <option value="G1N5">5</option>
            <option value="G1N6">6</option>
            <option value="G1N7">7</option>
            <option value="G1N8">8</option>
            <option value="G1N9">9</option>
            <option value="G1N10">10</option>
            <option value="G1N11">11</option>
            <option value="G1N12">12</option>
          </select>
          {/* grid four */}
          <select
            id="selectId"
            placeholder=" "
            onChange={(e) => setGridFour(e.target.value)}
            style={{ top: "184px", left: "114px" }}
          >
            <option selected value="">
              --
            </option>
            <option value="G4N1">1</option>
            <option value="G4N2">2</option>
            <option value="G4N3">3</option>
            <option value="G4N4">4</option>
            <option value="G4N5">5</option>
            <option value="G4N6">6</option>
            <option value="G4N7">7</option>
            <option value="G4N8">8</option>
            <option value="G4N9">9</option>
            <option value="G4N10">10</option>
            <option value="G4N11">11</option>
            <option value="G4N12">12</option>
          </select>
          {/* grid five */}
          <select
            id="selectId"
            placeholder=" "
            onChange={(e) => setGridFive(e.target.value)}
            style={{ top: "185px", right: "115px" }}
          >
            <option selected value="">
              --
            </option>
            <option value="G5N1">1</option>
            <option value="G5N2">2</option>
            <option value="G5N3">3</option>
            <option value="G5N4">4</option>
            <option value="G5N5">5</option>
            <option value="G5N6">6</option>
            <option value="G5N7">7</option>
            <option value="G5N8">8</option>
            <option value="G5N9">9</option>
            <option value="G5N10">10</option>
            <option value="G5N11">11</option>
            <option value="G5N12">12</option>
          </select>

          {/* === bottom === */}
          {/* grid eight */}
          <select
            id="selectId"
            placeholder=" "
            onChange={(e) => setGridEight(e.target.value)}
            style={{ bottom: "60px", right: "189px" }}
          >
            <option selected value="">
              --
            </option>
            <option value="G8N1">1</option>
            <option value="G8N2">2</option>
            <option value="G8N3">3</option>
            <option value="G8N4">4</option>
            <option value="G8N5">5</option>
            <option value="G8N6">6</option>
            <option value="G8N7">7</option>
            <option value="G8N8">8</option>
            <option value="G8N9">9</option>
            <option value="G8N10">11</option>
            <option value="G8N11">22</option>
            <option value="G8N12">33</option>
          </select>
          {/* grid nine */}
          <select
            id="selectId"
            placeholder=" "
            onChange={(e) => setGridNine(e.target.value)}
            style={{ bottom: "106px", right: "116px" }}
          >
            <option selected value="">
              --
            </option>
            <option value="G9N1">1</option>
            <option value="G9N2">2</option>
            <option value="G9N3">3</option>
            <option value="G9N4">4</option>
            <option value="G9N5">5</option>
            <option value="G9N6">6</option>
            <option value="G9N7">7</option>
            <option value="G9N8">8</option>
            <option value="G9N9">9</option>
            <option value="G9N10">11</option>
            <option value="G9N11">22</option>
            <option value="G9N12">33</option>
          </select>
          {/* grid saven */}
          <select
            id="selectId"
            placeholder=" "
            onChange={(e) => setGridSeven(e.target.value)}
            style={{ bottom: "107px", left: "112px" }}
          >
            <option selected value="">
              --
            </option>
            <option value="G7N1">1</option>
            <option value="G7N2">2</option>
            <option value="G7N3">3</option>
            <option value="G7N4">4</option>
            <option value="G7N5">5</option>
            <option value="G7N6">6</option>
            <option value="G7N7">7</option>
            <option value="G7N8">8</option>
            <option value="G7N9">9</option>
            <option value="G7N10">11</option>
            <option value="G7N11">22</option>
            <option value="G7N12">33</option>
          </select>
        </Box>
        <Flex mt={10} flexDir="column" gap={5}>
          <Button
            onClick={generate}
            variant="solid"
            bg="tomato"
            _hover={{ bg: "blue.400", color: "white" }}
            color="white"
            my={10}
          >
            {/* <Box onClick={}>Generate report</Box> */}
            {loading ? <Spinner /> : "Generate report"}
          </Button>
        </Flex>
        <VStack align="stretch" pb={20}>
          <HStack
            justifyContent="space-between"
            spacing={16}
            align="left"
            fontWeight="semibold"
            fontSize="lg"
          >
            <HStack spacing={5}>
              <Text fontWeight="bold">GRID 7</Text>
              <Text borderBottom="2px" textAlign="center" w="8">
                {alphaNum?.dathOfBirth}
              </Text>
            </HStack>
          </HStack>

          <HStack
            justifyContent="space-between"
            spacing={16}
            align="left"
            fontWeight="semibold"
            fontSize="lg"
          >
            <HStack spacing={5}>
              <Text fontWeight="bold">GRID 8</Text>
              <Text borderBottom="2px" textAlign="center" w="8">
                {alphaNum?.name}
              </Text>
            </HStack>
          </HStack>

          <HStack
            justifyContent="space-between"
            spacing={16}
            align="left"
            fontWeight="semibold"
            fontSize="lg"
          >
            <HStack spacing={5}>
              <Text fontWeight="bold">GRID 9</Text>
              <Text borderBottom="2px" textAlign="center" w="8">
                {result}
              </Text>
            </HStack>
          </HStack>
        </VStack>
      </VStack>
    </>
  );
};

export default InputNumber;
