import { createSlice } from "@reduxjs/toolkit";
import { defaultValues, isMatchNumber } from "../../utils/defualtValue";

interface IInitialState {
  name?: string | number | undefined | null;
  dathOfBirth?: string | number | undefined | null;
}

interface IPayload {
  name?: any;
  dathOfBirth?: any;
}

const initialState: IInitialState = {
  name: null,
  dathOfBirth: null,
};

const alphaToNumberSlice = createSlice({
  name: "alphaToNumber",
  initialState,
  reducers: {
    setAlphaToNumber: (state: IInitialState, action: { payload: IPayload }) => {
      const payload = action.payload;
      let nameSum: number = 0;
      let dobSum: number = 0;

      /**
       * for name sum start
       */
      const characters = payload.name.toLowerCase().split("");

      characters.forEach((char: any) => {
        if (defaultValues[char]) {
          nameSum += defaultValues[char];
        }
      });

      if (isMatchNumber.includes(nameSum)) {
        console.log(
          `The sum of ${payload.name} is ${nameSum} which is a match!`
        );
      } else {
        while (nameSum > 9) {
          let sum = 0;
          nameSum
            .toString()
            .split("")
            .forEach((digit) => {
              sum += parseInt(digit);
            });
          nameSum = sum;
          if (isMatchNumber.includes(nameSum)) {
            nameSum = nameSum;
            break;
          }
        }
      }
      /**
       * for name sum end
       */
      const dob = payload.dathOfBirth.replace(/-/g, "");
      dob.split("").forEach((digit: any) => {
        dobSum += parseInt(digit);
      });
      if (isMatchNumber.includes(dobSum)) {
        console.log(
          `The sum of ${payload.dathOfBirth} is ${dobSum}, which is a match!`
        );
      } else {
        while (dobSum > 9) {
          let sum = 0;
          dobSum
            .toString()
            .split("")
            .forEach((digit) => {
              sum += parseInt(digit);
            });
          dobSum = sum;
          if (isMatchNumber.includes(dobSum)) {
            dobSum = dobSum;
            break;
          }
        }
        /**
         * for dob sum end
         */
      }
      state.name = nameSum;
      state.dathOfBirth = dobSum;
    },
    removeAlpha: (state) => {
      state.name = null;
      state.dathOfBirth = null;
    },
  },
});

export const { setAlphaToNumber, removeAlpha } = alphaToNumberSlice.actions;
export default alphaToNumberSlice.reducer;
