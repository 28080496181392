import {
  Flex,
  Heading,
  Icon,
  IconButton,
  Stack,
  useColorMode,
} from "@chakra-ui/react";
import { useState } from "react";
import { BiData } from "react-icons/bi";
import { CgProfile } from "react-icons/cg";
import { FiFile, FiMenu } from "react-icons/fi";
import { ImUsers } from "react-icons/im";
import { RxDashboard } from "react-icons/rx";
import { Link } from "react-router-dom";
import SidebarItem from "./SidebarItem";

const Sidebar = () => {
  const [navSize, setNavSize] = useState<90 | 260>(260);
  const { colorMode } = useColorMode();
  return (
    <Stack
      position="sticky"
      top={0}
      left={0}
      zIndex={99}
      shadow="4px 0px 6px rgba(94, 73, 155, 0.04)"
      w={navSize}
      transition="ease .3s all"
      bg={colorMode === "dark" ? "black" : "white"}
      height="100vh"
      px={4}
    >
      <Flex align="center" justify="space-between" height="90px">
        {/* <Image
          src="/img/logo.png"
          display={navSize === 90 ? "none" : "unset"}
        /> */}
        <Heading
          fontSize={["16px", "20px", "25px", "32px"]}
          display={navSize === 90 ? "none" : "unset"}
        >
          <Link to="/dashboard">SBA</Link>
        </Heading>
        <IconButton
          display={{ base: "none", lg: "block" }}
          aria-label="toggle"
          variant="ghost"
          icon={<Icon as={FiMenu} fontSize="24px" />}
          onClick={() => setNavSize((prev) => (prev === 260 ? 90 : 260))}
        />
      </Flex>
      <Stack
        onMouseMove={() => setNavSize(260)}
        // onMouseLeave={() => setNavSize(90)}
        h="full"
        pt={5}
        overflowY="scroll"
        sx={{
          "&::-webkit-scrollbar": {
            width: "10px",
            display: "none",
            borderRadius: "8px",
            backgroundColor: `rgba(0, 0, 0, 0.05)`,
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: `rgba(0, 0, 0, 0.05)`,
          },
        }}
        align={{ base: "center", lg: "start" }}
      >
        <SidebarItem
          navSize={navSize}
          link="/dashboard"
          icon={<Icon as={RxDashboard} fontSize="24px" />}
          text="Dashboard"
        />
        <SidebarItem
          navSize={navSize}
          link="/dashboard/users"
          icon={<Icon as={ImUsers} fontSize="24px" />}
          text="User"
        />
        <SidebarItem
          navSize={navSize}
          link="/dashboard/report"
          icon={<Icon as={FiFile} fontSize="24px" />}
          text="Report"
        />

        <SidebarItem
          navSize={navSize}
          link="/dashboard/chart-data"
          icon={<Icon as={BiData} fontSize="24px" />}
          text="Data"
        />
        <SidebarItem
          navSize={navSize}
          link="/dashboard/profile"
          icon={<Icon as={CgProfile} fontSize="24px" />}
          text="Profile"
        />
      </Stack>
    </Stack>
  );
};

export default Sidebar;
