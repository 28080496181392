import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import useAuth from "../hooks/useAuth";

const AuthLayout = () => {
  const { session } = useAuth();
  return session?.accessToken ? <Navigate to="/" /> : <Outlet />;
};

export default AuthLayout;
