import { createContext } from "react";

export type Role = "ADMIN" | "CLIENT";

export interface IUser {
  uuid: string;
  name: string;
  email: string;
  role: Role;
  username: string;
  generatedReport: number;
  createdAt: string;
}

export interface Session {
  user: IUser;
  accessToken: string;
}

export interface IAuthContextState {
  session: Session | undefined;
}

export const defaultAuthContextState: IAuthContextState = {
  session: undefined,
};

export type TAuthContextActions = "set_auth" | "unset_auth";

export type TAuthContextPayload = Session | undefined;

export interface IAuthContextActions {
  type: TAuthContextActions;
  payload: TAuthContextPayload;
}

export const AuthReducer = (
  state: IAuthContextState,
  action: IAuthContextActions
) => {
  switch (action.type) {
    case "set_auth": {
      return { ...state, session: action.payload as Session };
    }
    case "unset_auth": {
      return { ...state, session: undefined as undefined };
    }
    default:
      return { ...state };
  }
};

interface IAuthContextProps {
  authState: IAuthContextState;
  authDispatch: React.Dispatch<IAuthContextActions>;
}
const AuthContext = createContext<IAuthContextProps>({
  authState: defaultAuthContextState,
  authDispatch: () => {},
});

export const AuthContextConsumer = AuthContext.Consumer;
export const AuthContextProvider = AuthContext.Provider;

export default AuthContext;
