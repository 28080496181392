import { Box, Flex, HStack, Image, Img, Text, VStack } from "@chakra-ui/react";
import moment from "moment";
import { useContext } from "react";
import chartImage from "../../../assets/img/img.jpg";
import logo from "../../../assets/img/LOGO.png";
import Copywring from "../../../components/common/Copywring";
import SmNumber, { InputDisplayNumber } from "../../../components/Sm/SmNumber";
import NumberContext from "../../../context/SmNumberContext/NumberContext";

interface IUserInfo {
  data?: any;
  reportData?: any;
}

const UserInformation: React.FC<IUserInfo> = ({ data, reportData }) => {
  const { numberState } = useContext(NumberContext);

  return (
    <Box
      className="page-break"
      h="auto"
      overflow="hidden"
      w="full"
      position="relative"
    >
      <Text fontSize="16px" textAlign="center" textTransform="uppercase" pb={6}>
        &copy; The bliss academy &#174;
      </Text>
      <Flex w="full" align="center" justify="center">
        <Img src={logo} w="30rem" h="auto" py={0} my={0} alt="LOGO" />
      </Flex>
      <VStack spacing={5} align="left" pt={10}>
        {/* date */}
        <HStack spacing={3}>
          <Text fontSize="18px" fontWeight="600" textTransform="uppercase">
            Date:
          </Text>
          <Text fontSize="18px" borderBottom="2px" px={5}>
            {moment().format("D MMM, YYYY")}
          </Text>
        </HStack>
        {/* name */}
        <HStack spacing={3}>
          <Text fontSize="18px" fontWeight="600" textTransform="uppercase">
            name:
          </Text>
          <Text fontSize="18px" borderBottom="2px" px={5}>
            {data && data?.name}
          </Text>
        </HStack>
        <Flex w="full" align="center" justify="center" my={3}>
          <Box position="relative">
            <Image w="64" textAlign="center" src={chartImage} alt="chart" />
            {/* sm */}

            {/* sm */}
            {reportData.map((item: any) => (
              <>
                {/* 3 start */}
                <SmNumber
                  fontWeight="bold"
                  fontSize={20}
                  top={42}
                  left={113}
                  number={
                    item?.GNPN === "G3N1"
                      ? 1
                      : item?.GNPN === "G3N2"
                      ? 2
                      : item?.GNPN === "G3N3"
                      ? 3
                      : item?.GNPN === "G3N4"
                      ? 4
                      : item?.GNPN === "G3N5"
                      ? 5
                      : item?.GNPN === "G3N6"
                      ? 6
                      : item?.GNPN === "G3N7"
                      ? 7
                      : item?.GNPN === "G3N8"
                      ? 8
                      : item?.GNPN === "G3N9"
                      ? 9
                      : item?.GNPN === "G3N10"
                      ? 10
                      : item?.GNPN === "G3N11"
                      ? 11
                      : item?.GNPN === "G3N12"
                      ? 12
                      : ""
                  }
                />
                <InputDisplayNumber
                  number={
                    numberState?.numbers?.numberThree
                      ? numberState?.numbers?.numberThree
                      : ""
                  }
                  fontSize={14}
                  top={24}
                  left={119}
                />
                {/* 3 end */}
                {/* 6 start */}
                <SmNumber
                  fontWeight="bold"
                  fontSize={20}
                  top={113}
                  left={112}
                  number={
                    item?.GNPN === "G6N1"
                      ? 1
                      : item?.GNPN === "G6N2"
                      ? 2
                      : item?.GNPN === "G6N3"
                      ? 3
                      : item?.GNPN === "G6N4"
                      ? 4
                      : item?.GNPN === "G6N5"
                      ? 5
                      : item?.GNPN === "G6N6"
                      ? 6
                      : item?.GNPN === "G6N7"
                      ? 7
                      : item?.GNPN === "G6N8"
                      ? 8
                      : item?.GNPN === "G6N9"
                      ? 9
                      : item?.GNPN === "G6N10"
                      ? 10
                      : item?.GNPN === "G6N11"
                      ? 11
                      : item?.GNPN === "G6N12"
                      ? 12
                      : ""
                  }
                />
                <InputDisplayNumber
                  number={
                    numberState?.numbers?.numberSix
                      ? numberState?.numbers?.numberSix
                      : ""
                  }
                  fontSize={14}
                  top={96}
                  left={118}
                />
                {/* 6 end */}

                {/* 8 start */}
                <SmNumber
                  fontWeight="bold"
                  fontSize={20}
                  top={193}
                  left={112}
                  number={
                    item?.GNPN === "G8N1"
                      ? 1
                      : item?.GNPN === "G8N2"
                      ? 2
                      : item?.GNPN === "G8N3"
                      ? 3
                      : item?.GNPN === "G8N4"
                      ? 4
                      : item?.GNPN === "G8N5"
                      ? 5
                      : item?.GNPN === "G8N6"
                      ? 6
                      : item?.GNPN === "G8N7"
                      ? 7
                      : item?.GNPN === "G8N8"
                      ? 8
                      : item?.GNPN === "G8N9"
                      ? 9
                      : item?.GNPN === "G8N10"
                      ? 11
                      : item?.GNPN === "G8N11"
                      ? 22
                      : item?.GNPN === "G8N12"
                      ? 33
                      : ""
                  }
                />
                <InputDisplayNumber
                  fontSize={14}
                  top={182}
                  left={102}
                  number={
                    numberState?.numbers?.numberEight
                      ? numberState?.numbers?.numberEight
                      : ""
                  }
                />
                <InputDisplayNumber
                  fontSize={14}
                  top={182}
                  left={135}
                  number={
                    numberState?.numbers?.numberEightTwo
                      ? numberState?.numbers?.numberEightTwo
                      : ""
                  }
                />
                {/* 8 end */}

                {/* 2 start */}
                <SmNumber
                  fontSize={20}
                  fontWeight="bold"
                  top={113}
                  right={16}
                  number={
                    item?.GNPN === "G1N1"
                      ? 7
                      : item?.GNPN === "G1N2"
                      ? 8
                      : item?.GNPN === "G1N3"
                      ? 9
                      : item?.GNPN === "G1N4"
                      ? 10
                      : item?.GNPN === "G1N5"
                      ? 11
                      : item?.GNPN === "G1N6"
                      ? 12
                      : item?.GNPN === "G1N7"
                      ? 1
                      : item?.GNPN === "G1N8"
                      ? 2
                      : item?.GNPN === "G1N9"
                      ? 3
                      : item?.GNPN === "G1N10"
                      ? 4
                      : item?.GNPN === "G1N11"
                      ? 5
                      : item?.GNPN === "G1N12"
                      ? 6
                      : ""
                  }
                />
                <InputDisplayNumber
                  number={
                    numberState?.numbers?.numberTwo
                      ? numberState?.numbers?.numberTwo
                      : ""
                  }
                  top={95}
                  right={22}
                  fontSize={14}
                />
                {/* 2 end */}

                {/* 4 start */}
                <SmNumber
                  fontWeight="bold"
                  fontSize={20}
                  top={113}
                  left={65}
                  number={
                    item?.GNPN === "G4N1"
                      ? 1
                      : item?.GNPN === "G4N2"
                      ? 2
                      : item?.GNPN === "G4N3"
                      ? 3
                      : item?.GNPN === "G4N4"
                      ? 4
                      : item?.GNPN === "G4N5"
                      ? 5
                      : item?.GNPN === "G4N6"
                      ? 6
                      : item?.GNPN === "G4N7"
                      ? 7
                      : item?.GNPN === "G4N8"
                      ? 8
                      : item?.GNPN === "G4N9"
                      ? 9
                      : item?.GNPN === "G4N10"
                      ? 10
                      : item?.GNPN === "G4N11"
                      ? 11
                      : item?.GNPN === "G4N12"
                      ? 12
                      : ""
                  }
                />
                <InputDisplayNumber
                  fontSize={14}
                  top={96}
                  left={72}
                  number={
                    numberState?.numbers?.numberFour
                      ? numberState?.numbers?.numberFour
                      : ""
                  }
                />
                {/* 4 end */}

                {/* 5 start */}
                <SmNumber
                  fontWeight="bold"
                  fontSize={20}
                  top={114}
                  right={67}
                  number={
                    item?.GNPN === "G5N1"
                      ? 1
                      : item?.GNPN === "G5N2"
                      ? 2
                      : item?.GNPN === "G5N3"
                      ? 3
                      : item?.GNPN === "G5N4"
                      ? 4
                      : item?.GNPN === "G5N5"
                      ? 5
                      : item?.GNPN === "G5N6"
                      ? 6
                      : item?.GNPN === "G5N7"
                      ? 7
                      : item?.GNPN === "G5N8"
                      ? 8
                      : item?.GNPN === "G5N9"
                      ? 9
                      : item?.GNPN === "G5N10"
                      ? 10
                      : item?.GNPN === "G5N11"
                      ? 11
                      : item?.GNPN === "G5N12"
                      ? 12
                      : ""
                  }
                />
                <InputDisplayNumber
                  fontSize={14}
                  top={96}
                  right={73}
                  number={
                    numberState?.numbers?.numberFive
                      ? numberState?.numbers?.numberFive
                      : ""
                  }
                />
                {/* 5 end */}

                {/* 1 start */}
                <SmNumber
                  fontWeight="bold"
                  fontSize={20}
                  top={113}
                  left={15}
                  number={
                    item?.GNPN === "G1N1"
                      ? 1
                      : item?.GNPN === "G1N2"
                      ? 2
                      : item?.GNPN === "G1N3"
                      ? 3
                      : item?.GNPN === "G1N4"
                      ? 4
                      : item?.GNPN === "G1N5"
                      ? 5
                      : item?.GNPN === "G1N6"
                      ? 6
                      : item?.GNPN === "G1N7"
                      ? 7
                      : item?.GNPN === "G1N8"
                      ? 8
                      : item?.GNPN === "G1N9"
                      ? 9
                      : item?.GNPN === "G1N10"
                      ? 10
                      : item?.GNPN === "G1N11"
                      ? 11
                      : item?.GNPN === "G1N12"
                      ? 12
                      : ""
                  }
                />
                <InputDisplayNumber
                  fontSize={14}
                  top={96}
                  left={20}
                  number={
                    numberState?.numbers?.numberOne
                      ? numberState?.numbers?.numberOne
                      : ""
                  }
                />
                {/* 1 end */}

                {/* 7 start */}
                <SmNumber
                  fontWeight="bold"
                  fontSize={20}
                  top={163}
                  left={65}
                  number={
                    item?.GNPN === "G7N1"
                      ? 1
                      : item?.GNPN === "G7N2"
                      ? 2
                      : item?.GNPN === "G7N3"
                      ? 3
                      : item?.GNPN === "G7N4"
                      ? 4
                      : item?.GNPN === "G7N5"
                      ? 5
                      : item?.GNPN === "G7N6"
                      ? 6
                      : item?.GNPN === "G7N7"
                      ? 7
                      : item?.GNPN === "G7N8"
                      ? 8
                      : item?.GNPN === "G7N9"
                      ? 9
                      : item?.GNPN === "G7N10"
                      ? 11
                      : item?.GNPN === "G7N11"
                      ? 22
                      : item?.GNPN === "G7N12"
                      ? 33
                      : ""
                  }
                />
                <InputDisplayNumber
                  fontSize={14}
                  top={153}
                  left={54}
                  number={
                    numberState?.numbers?.numberSeven
                      ? numberState?.numbers?.numberSeven
                      : ""
                  }
                />
                <InputDisplayNumber
                  fontSize={14}
                  top={153}
                  left={88}
                  number={
                    numberState?.numbers?.numberSevenTwo
                      ? numberState?.numbers?.numberSevenTwo
                      : ""
                  }
                />
                {/* 7 end */}

                {/* 9 start */}
                <SmNumber
                  fontWeight="bold"
                  fontSize={20}
                  top={164}
                  right={66}
                  number={
                    item?.GNPN === "G9N1"
                      ? 1
                      : item?.GNPN === "G9N2"
                      ? 2
                      : item?.GNPN === "G9N3"
                      ? 3
                      : item?.GNPN === "G9N4"
                      ? 4
                      : item?.GNPN === "G9N5"
                      ? 5
                      : item?.GNPN === "G9N6"
                      ? 6
                      : item?.GNPN === "G9N7"
                      ? 7
                      : item?.GNPN === "G9N8"
                      ? 8
                      : item?.GNPN === "G9N9"
                      ? 9
                      : item?.GNPN === "G9N10"
                      ? 11
                      : item?.GNPN === "G9N11"
                      ? 22
                      : item?.GNPN === "G9N12"
                      ? 33
                      : ""
                  }
                />
                <InputDisplayNumber
                  fontSize={14}
                  top={153}
                  right={87}
                  number={
                    numberState?.numbers?.numberNine
                      ? numberState?.numbers?.numberNine
                      : ""
                  }
                />
                <InputDisplayNumber
                  fontSize={14}
                  top={152}
                  right={56}
                  number={
                    numberState?.numbers?.numberNineTwo
                      ? numberState?.numbers?.numberNineTwo
                      : ""
                  }
                />
                {/* 9 end */}
              </>
            ))}
          </Box>
        </Flex>
        {/* full name at birth */}
        <HStack spacing={3}>
          <Text fontSize="18px" fontWeight="600" textTransform="uppercase">
            full name at birth:
          </Text>
          <Text fontSize="18px" borderBottom="2px" px={5}>
            {data && data?.fullNameAtBirth}
          </Text>
        </HStack>
        {/* date of birth */}
        <HStack spacing={3}>
          <Text fontSize="18px" fontWeight="600" textTransform="uppercase">
            Date of birth:
          </Text>
          <Text fontSize="18px" borderBottom="2px" px={5}>
            {data?.dateOfBirth
              ? moment(data?.dateOfBirth).format("D MMM, YYYY")
              : null}
          </Text>
        </HStack>
        {/* time of birth */}
        <HStack spacing={3}>
          <Text fontSize="18px" fontWeight="600" textTransform="uppercase">
            time of birth:
          </Text>
          <Text fontSize="18px" borderBottom="2px" px={5}>
            {data && data?.timeOfBirth}
          </Text>
        </HStack>
        {/* place of birth */}
        <HStack spacing={3}>
          <Text fontSize="18px" fontWeight="600" textTransform="uppercase">
            place of birth
          </Text>
        </HStack>
        {/* Town */}
        <HStack spacing={3}>
          <Text fontSize="18px" fontWeight="600" textTransform="uppercase">
            Town:
          </Text>
          <Text fontSize="18px" borderBottom="2px" px={5}>
            {data && data?.town}
          </Text>
        </HStack>
        {/* country */}
        <HStack spacing={3}>
          <Text fontSize="18px" fontWeight="600" textTransform="uppercase">
            country:
          </Text>
          <Text fontSize="18px" borderBottom="2px" px={5}>
            {data && data?.country}
          </Text>
        </HStack>
      </VStack>
      <Copywring />
    </Box>
  );
};

export default UserInformation;
