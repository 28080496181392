import { Text } from "@chakra-ui/react";
import React from "react";

interface IGrid {
  text: string;
}
const Grid: React.FC<IGrid> = ({ text }) => {
  const justGrid = text.split("N").splice(0, 1)[0];

  return (
    <>
      <Text
        textAlign="right"
        fontWeight="bold"
        pos="absolute"
        right={0}
        top={0}
      >
        GRID{" "}
        {justGrid === "G1"
          ? 1
          : justGrid === "G2"
          ? 2
          : justGrid === "G3"
          ? 3
          : justGrid === "G4"
          ? 4
          : justGrid === "G5"
          ? 5
          : justGrid === "G6"
          ? 6
          : justGrid === "G7"
          ? 7
          : justGrid === "G8"
          ? 8
          : justGrid === "G9"
          ? 9
          : 0}
      </Text>
    </>
  );
};

export default Grid;
