import {
  Button,
  Flex,
  Heading,
  HStack,
  Stack,
  Text,
  useColorMode,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import moment from "moment";
import { BsArrowLeftCircle } from "react-icons/bs";
import { Link, useParams } from "react-router-dom";
import { IUser } from "../../context/AuthContext/AuthContext";
import useAxios from "../../hooks/useAxios";
import Error from "../common/Error";
import Loading from "../common/Loading";
import UserFrom from "../form/UserFrom";

const UserViews = () => {
  const { onOpen, isOpen, onClose } = useDisclosure();
  const { colorMode } = useColorMode();
  const axios = useAxios();
  const { id } = useParams();

  const {
    data: user,
    isLoading,
    isError,
  } = useQuery<IUser>({
    queryKey: ["users", id],
    queryFn: () => axios.get(`/api/users/${id}`).then((res) => res.data),
  });

  if (isLoading) return <Loading />;
  if (isError)
    return <Error message="User not found somthing went to server" />;

  return (
    <>
      <UserFrom
        mode="EDIT"
        user={user}
        id={id}
        onOpen={onOpen}
        isOpen={isOpen}
        onClose={onClose}
      />
      <Flex
        bg={colorMode === "dark" ? "black" : "white"}
        rounded="10px"
        boxShadow="0px 4px 6px rgba(94, 73, 155, 0.04)"
        flexDir="column"
        gap={6}
      >
        {/* page header */}
        <Stack
          borderBottom="1px"
          borderBottomColor="#a6aebb4d"
          w="full"
          p={[2, 5]}
        >
          <Heading fontSize="20px" fontWeight="500">
            View Single Report
          </Heading>
        </Stack>

        <VStack px={[2, 14]} pb={[2, 5]} spacing={[2, 4]} textAlign="left">
          <VStack align="left" w="full" textAlign="left" spacing={5}>
            <Flex
              alignItems="flex-start"
              flexDir={["column", "column", "row"]}
              gap={10}
              justify="space-between"
            >
              <Text w="50%" align="left">
                <strong>NAME: </strong>
                {user?.name ? user?.name : "Empty"}
              </Text>
              <Text w="50%" align="left">
                <strong>Email: </strong>
                {user?.email ? user?.email : "Empty"}
              </Text>
              <Text w="50%" align="left">
                <strong>USERNAME: </strong>
                {user?.username ? user?.username : "Empty"}
              </Text>
            </Flex>
            <Flex
              flexDir={["column", "column", "row"]}
              gap={5}
              justify="space-between"
            >
              <Text w="50%" align="left">
                <strong>ROLE: </strong>
                {user?.role ? user?.role : "Empty"}
              </Text>
              <Text w="50%" align="left">
                <strong>Created At: </strong>
                {moment(user?.createdAt).startOf("seconds").fromNow()}
              </Text>
            </Flex>
            <Flex
              flexDir={["column", "column", "row"]}
              gap={5}
              justify="space-between"
            >
              <Text w="50%" align="left">
                <strong>Generated Report: </strong>
                {user?.generatedReport ? user?.generatedReport : "Empty"}
              </Text>
            </Flex>
          </VStack>

          <HStack justify="space-between" w="full" py={5}>
            <Button
              as={Link}
              to="/dashboard/users"
              leftIcon={<BsArrowLeftCircle />}
              fontSize="16px"
              fontWeight="400"
              rounded="6px"
            >
              Back
            </Button>
            <Button
              onClick={onOpen}
              type="submit"
              fontSize="16px"
              fontWeight="400"
              bg="#FF6633"
              color="white"
              rounded="6px"
              width="130px"
              _hover={{ bg: "#fb4509", transition: "ease 0.3s all" }}
            >
              Update
            </Button>
          </HStack>
        </VStack>
      </Flex>
    </>
  );
};

export default UserViews;
