import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Image,
  Input,
  Spinner,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useContext, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import chartImage from "../../assets/img/img.jpg";
import loadingImage from "../../assets/img/loading.gif";
import logo from "../../assets/img/LOGO.png";
import InputContext from "../../context/ReportContext/ReportContext";
import useAuth from "../../hooks/useAuth";
import useAxios from "../../hooks/useAxios";
import useFormatTime from "../../hooks/useTime";
import { useDispatch } from "react-redux";
import { setAlphaToNumber } from "../../redux/slice/alphaToNumberSlice";

const Chart = () => {
  window.document.title = "SOUL BLUEPRINT ALIGNMENT - Chart";
  const dispatch = useDispatch();
  const { session } = useAuth();
  const [date, setDate] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [fullNameAtBirth, setFullNameAtBirth] = useState<string>("");
  const [dateOfBirth, setDateOfBirth] = useState<string>("");
  const [town, setTown] = useState<string>("");
  const [country, setCountry] = useState<string>("");
  const [charts, setChart] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const { inputDispatch } = useContext(InputContext);
  const axios = useAxios();
  const { time: timeOfBirth, formatTime } = useFormatTime();
  const navigate = useNavigate();

  const onSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    const data = {
      date,
      name,
      fullNameAtBirth,
      dateOfBirth,
      timeOfBirth,
      town,
      country,
      charts,
      username: session?.user?.username,
    };

    try {
      const res = await axios.post("/api/chart-data", data);
      const mess = res?.data;
      inputDispatch({ type: "set_input", payload: data });
      dispatch(
        setAlphaToNumber({ name: fullNameAtBirth, dathOfBirth: dateOfBirth })
      );
      toast.success(mess?.message);
      navigate("/input");
      setLoading(false);
    } catch (error) {
      toast.error("Something went wrong!");
      setLoading(false);
    }
  };
  return (
    <>
      <form onSubmit={onSubmit}>
        <Flex
          gap={[3, 5, 7, 10]}
          py={[10, 12, 14]}
          align={["center", "center", "center", "unset"]}
          w={["full", "full", "880px"]}
          maxW={["full", "full", "full"]}
          flexDir={["column", "column", "column", "row"]}
          textTransform="uppercase"
        >
          <VStack
            id="left_side"
            pr={[0, 0, 14]}
            w={["full", "full", "50%"]}
            spacing={[5, 8]}
          >
            <Image
              mt={[7, 6, 5, 3]}
              src={logo}
              alt="logo"
              w={["full", "full", "full", "96"]}
              pb={[8, 12]}
            />
            <Heading py={0} fontSize={["lg", "2xl"]}>
              Select Chart
            </Heading>
            <Flex
              gap={[2, 2]}
              w={["full"]}
              flexDir={["column", "column", "row"]}
            >
              <VStack w="full">
                {chartImage ? (
                  <Image
                    border={charts === "CHART_ONE" ? "2px" : "unset"}
                    rounded="full"
                    onClick={() => setChart("CHART_ONE")}
                    src={chartImage}
                    w={["80%", "50%", "full"]}
                    objectFit="cover"
                    alt="home image"
                    fallbackSrc={loadingImage}
                  />
                ) : (
                  <Spinner size="lg" />
                )}
                <Text textTransform="uppercase">Chart 1</Text>
              </VStack>
              <VStack w="full">
                {chartImage ? (
                  <Image
                    border={charts === "CHART_TWO" ? "2px" : "unset"}
                    rounded="full"
                    onClick={() => setChart("CHART_TWO")}
                    src={chartImage}
                    w={["80%", "50%", "full"]}
                    objectFit="cover"
                    alt="home image"
                    fallbackSrc={loadingImage}
                  />
                ) : (
                  <Spinner size="lg" />
                )}
                <Text textTransform="uppercase">Chart 2</Text>
              </VStack>
            </Flex>
          </VStack>
          <Stack spacing={0} w={["100%", "100%", "50%"]} maxW="full">
            <VStack py={3} spacing={5} w="full">
              <FormControl>
                <FormLabel htmlFor="date">Date</FormLabel>
                <Input
                  onChange={(e: any) => setDate(e.target.value)}
                  id="date"
                  size="md"
                  borderBottomColor="gray.200"
                  variant="flushed"
                  px="2"
                  placeholder="Date"
                  type="date"
                />
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="name">Name</FormLabel>
                <Input
                  onChange={(e: any) => setName(e.target.value)}
                  id="name"
                  size="md"
                  borderBottomColor="gray.200"
                  variant="flushed"
                  px="2"
                  placeholder="Name"
                  type="text"
                  value={name}
                />
              </FormControl>

              <FormControl>
                <FormLabel htmlFor="fullNameAtBirth">
                  Full name at birth
                </FormLabel>
                <Input
                  onChange={(e: any) => setFullNameAtBirth(e.target.value)}
                  id="fullNameAtBirth"
                  borderBottomColor="gray.200"
                  variant="flushed"
                  px="2"
                  size="md"
                  placeholder="Full name at birth"
                  type="text"
                />
              </FormControl>

              <FormControl>
                <FormLabel htmlFor="dateOfBirth">Date of birth </FormLabel>
                <Input
                  onChange={(e: any) => setDateOfBirth(e.target.value)}
                  id="dateOfBirth"
                  borderBottomColor="gray.200"
                  variant="flushed"
                  px="2"
                  size="md"
                  placeholder="Date of birth"
                  type="date"
                />
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="timeOfBirth">Time of birth </FormLabel>
                <Input
                  id="timeOfBirth"
                  onChange={(e: any) => formatTime(e.target.value)}
                  borderBottomColor="gray.200"
                  variant="flushed"
                  px="2"
                  size="md"
                  placeholder="Time Of birth"
                  type="time"
                />
              </FormControl>
              <Box w="full">
                <Heading fontSize="16px" textAlign={"left"}>
                  Place of birth
                </Heading>
              </Box>

              <FormControl>
                <FormLabel htmlFor="town">Town / State </FormLabel>
                <Input
                  id="town"
                  onChange={(e: any) => setTown(e.target.value)}
                  borderBottomColor="gray.200"
                  variant="flushed"
                  px="2"
                  size="md"
                  placeholder="Town"
                  type="text"
                />
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="country">Country </FormLabel>
                <Input
                  id="country"
                  onChange={(e: any) => setCountry(e.target.value)}
                  borderBottomColor="gray.200"
                  variant="flushed"
                  px="2"
                  size="md"
                  placeholder="Country"
                  type="text"
                />
              </FormControl>

              <Flex py={4}>
                <Button variant="solid" colorScheme="orange" type="submit">
                  {loading ? <Spinner /> : "Next"}
                </Button>
              </Flex>
            </VStack>
          </Stack>
        </Flex>
      </form>
    </>
  );
};
export default Chart;
