import { Flex, IconButton, Stack, useColorMode } from "@chakra-ui/react";
import { BsMoonStarsFill } from "react-icons/bs";
import { FaSun } from "react-icons/fa";
import { Navigate, Outlet } from "react-router-dom";
import Sidebar from "../components/Sidebar/Sidebar";
import Tobpar from "../components/Topbar/Tobpar";
import useAuth from "../hooks/useAuth";

const MainLayout = () => {
  const { session } = useAuth();
  const { colorMode, toggleColorMode } = useColorMode();

  return session?.accessToken && session?.user.role === "ADMIN" ? (
    <Flex>
      <IconButton
        aria-label="icon"
        cursor="pointer"
        as={colorMode === "dark" ? FaSun : BsMoonStarsFill}
        zIndex={9999999}
        position="absolute"
        left={7}
        bottom={7}
        p={2}
        size="md"
        variant="solid"
        colorScheme="blue"
        transition="all ease-in-out"
        transitionDuration="0.4s"
        onClick={toggleColorMode}
      />
      <Sidebar />
      <main style={{ width: "100%" }}>
        <Tobpar />

        <Stack p={4} mt="90px">
          <Outlet />
        </Stack>
      </main>
    </Flex>
  ) : session?.accessToken && session?.user?.role === "CLIENT" ? (
    <Navigate to="/" />
  ) : (
    <Navigate to="/login" />
  );
};

export default MainLayout;
