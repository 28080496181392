import {
  Button,
  Center,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Spinner,
  Stack,
  VStack,
} from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { toast } from "react-hot-toast";
import { Link } from "react-router-dom";
import useAxios from "../../hooks/useAxios";

const ResetPassword = () => {
  window.document.title = "SOUL BLUEPRINT ALIGNMENT - Reset Password";
  const axios = useAxios();
  const [email, setEmail] = useState("");
  const mutation = useMutation({
    mutationKey: ["password_reset"],
    mutationFn: () =>
      axios.post("/api/auth/reset-password", { email }).then((res) => res.data),
    onSuccess: (data: any) => {
      toast.success(data?.message);
    },
    onError: (error: any) => {
      if (error) {
        toast.error(error?.response?.data?.message);
      }
    },
  });
  console.log(mutation.data);
  return (
    <Center w="full" h="100vh">
      <Stack
        spacing={0}
        rounded="lg"
        w="md"
        align="center"
        p={[3, 7]}
        boxShadow="lg"
      >
        <Heading pb={[4, 7]} fontSize={["18px", "22px", "28px"]}>
          Enter your valid email
        </Heading>

        <VStack py={3} spacing={3} w="full">
          <FormControl isRequired>
            <FormLabel htmlFor="email">Email</FormLabel>
            <Input
              size="md"
              id="email"
              type="email"
              placeholder="Enter your valid email address"
              borderBottomColor="gray.200"
              variant="flushed"
              px="2"
              w="full"
              onChange={(e) => setEmail(e.target.value)}
            />
          </FormControl>

          <VStack w="full" spacing={0} pt={[2, 4]}>
            <Button
              onClick={() => mutation.mutate()}
              w="full"
              variant="solid"
              colorScheme="orange"
              type="submit"
              mb={2}
            >
              {mutation.isLoading ? <Spinner /> : "Send"}
            </Button>
            <Link
              to="/"
              style={{ textDecoration: "underline", color: "green" }}
            >
              Go back
            </Link>
          </VStack>
        </VStack>
      </Stack>
    </Center>
  );
};

export default ResetPassword;
