import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  Flex,
  Heading,
  Icon,
  Stack,
  Text,
  useColorMode,
  useDisclosure,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { createColumnHelper } from "@tanstack/react-table";
import moment from "moment";
import { useState } from "react";
import { AiOutlineEye, AiOutlinePlusCircle } from "react-icons/ai";
import { MdDelete } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import Alert from "../../components/common/Alert";
import Error from "../../components/common/Error";
import Loading from "../../components/common/Loading";
import Datatable from "../../components/Datatable/Datatable";
import { IUser } from "../../context/AuthContext/AuthContext";
import useAxios from "../../hooks/useAxios";

const Users = () => {
  window.document.title = "SOUL BLUEPRINT ALIGNMENT - Users";
  const { colorMode } = useColorMode();
  const axios = useAxios();
  const navigate = useNavigate();
  const [id, setId] = useState<string>("");
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { data, isLoading, isError } = useQuery({
    queryKey: ["users"],
    queryFn: () => axios.get("/api/users").then((res) => res.data),
  });

  const columnHelper = createColumnHelper<IUser>();
  const columns = [
    columnHelper.accessor("uuid", {
      header: () => "S.N",
      cell: (info) => info.row.index + 1,
    }),
    columnHelper.accessor("name", {
      header: () => "Full Name",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("email", {
      header: () => "Email Address",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("username", {
      header: () => "Username",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("role", {
      header: () => "Role",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("createdAt", {
      header: () => "Joinded at",
      cell: (info) => moment(info.getValue()).format("LL"),
    }),
    columnHelper.accessor("generatedReport", {
      header: () => "G.R",
      cell: (info) => info.getValue(),
    }),

    columnHelper.display({
      header: "Actions",
      id: "actions",
      cell: (props) => (
        <ButtonGroup>
          <Button
            onClick={() =>
              navigate(`/dashboard/users/view/${props?.row?.original?.uuid}`)
            }
            rounded="2xl"
            size="sm"
            fontSize="14px"
            colorScheme="green"
            leftIcon={<Icon mr={-1} as={AiOutlineEye} fontSize="16px" />}
          >
            Open
          </Button>
          <Button
            onClick={() => setId(props.row?.original?.uuid)}
            rounded="2xl"
            size="sm"
            fontSize="14px"
            colorScheme="red"
            leftIcon={<Icon mr={-1} as={MdDelete} fontSize="16px" />}
          >
            <Text onClick={onOpen}>Delete</Text>
          </Button>
        </ButtonGroup>
      ),
    }),
  ];
  if (isLoading) return <Loading />;
  if (isError) return <Error message="Server error!" />;
  return (
    <>
      <Alert
        isOpen={isOpen}
        onClose={onClose}
        message="Are you sure!🤚 to delete this USER"
        id={id}
        type="user"
      />
      <Card
        bg={colorMode === "dark" ? "blackAlpha.300" : "white"}
        id="from accont card"
        rounded="10px"
        boxShadow="0px 4px 6px rgba(94, 73, 155, 0.04)"
      >
        <Flex
          as={CardHeader}
          align="center"
          justify="space-between"
          borderBottom="0.5px solid #BFBFBF"
        >
          <Heading fontWeight={500} fontSize="20px">
            List of Users
          </Heading>
          <Stack direction="row">
            <Button
              as={Link}
              to="/dashboard/users/create"
              bg="primary.200"
              color="white"
              colorScheme="yellow"
              leftIcon={<Icon as={AiOutlinePlusCircle} />}
            >
              Add New User
            </Button>
          </Stack>
        </Flex>

        <CardBody>
          <Datatable data={data} columns={columns} />
        </CardBody>
      </Card>
    </>
  );
};

export default Users;
