import {
  Flex,
  Stack,
  Heading,
  VStack,
  HStack,
  Button,
  Text,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { BsArrowLeftCircle } from "react-icons/bs";
import { Link, useParams } from "react-router-dom";
import useAxios from "../../hooks/useAxios";

const View = () => {
  const { id } = useParams();
  const axios = useAxios();

  const reportQuery = useQuery({
    queryKey: ["data"],
    queryFn: () => axios.get(`/api/data/single/${id}`).then((res) => res.data),
  });

  const data = reportQuery.data;
  return (
    <Flex
      bg="white"
      rounded="10px"
      boxShadow="0px 4px 6px rgba(94, 73, 155, 0.04)"
      flexDir="column"
      gap={6}
    >
      {/* page header */}
      <Stack
        borderBottom="1px"
        borderBottomColor="#a6aebb4d"
        w="full"
        p={[2, 5]}
      >
        <Heading fontSize="20px" fontWeight="500">
          View Single Report
        </Heading>
      </Stack>

      <VStack px={[2, 14]} pb={[2, 5]} spacing={[2, 4]} textAlign="left">
        <VStack align="left" w="full" textAlign="left" spacing={5}>
          <Flex
            alignItems="flex-start"
            flexDir={["column", "column", "row"]}
            gap={10}
            justify="space-between"
          >
            <Text w="50%">
              <strong>GNPN: </strong>
              {data?.GNPN ? data?.GNPN : "Empty"}
            </Text>
            <Text w="50%">
              <strong>ALIGNED: </strong>
              {data?.aligned ? data?.aligned : "Empty"}
            </Text>
          </Flex>
          <Flex
            flexDir={["column", "column", "row"]}
            gap={5}
            justify="space-between"
          >
            <Text w="50%">
              <strong>BUTTOM BOX COLOR: </strong>
              {data?.bottom_box_color ? data?.bottom_box_color : "Empty"}
            </Text>
            <Text w="50%">
              <strong>BOX COLOR: </strong>
              {data?.box_color ? data?.box_color : "Empty"}
            </Text>
          </Flex>
          <Flex
            flexDir={["column", "column", "row"]}
            gap={5}
            justify="space-between"
          >
            <Text w="50%">
              <strong>PAGE NAME: </strong>
              {data?.pageName ? data?.pageName : "Empty"}
            </Text>
            <Text w="50%">
              <strong>PAGE NUMBER: </strong>
              {data?.pageNumber ? data?.pageNumber : "Empty"}
            </Text>
          </Flex>
          <Flex
            flexDir={["column", "column", "row"]}
            gap={5}
            justify="space-between"
          >
            <Text w="50%">
              <strong>PAGE AVOID: </strong>
              {data?.pageAvoid ? data?.pageAvoid : "Empty"}
            </Text>
            <Text w="50%">
              <strong>PAGE DESIRE: </strong>
              {data?.pageDesire ? data?.pageDesire : "Empty"}
            </Text>
          </Flex>
          <Flex
            flexDir={["column", "column", "row"]}
            gap={5}
            justify="space-between"
          >
            <Text w="50%">
              <strong>CART NUMBER: </strong>
              {data?.chart ? data?.chart : "Empty"}
            </Text>
            <Text w="50%">
              <strong>DOWN BOLD TEXT: </strong>
              {data?.downBoldText ? data?.downBoldText : "Empty"}
            </Text>
          </Flex>
          <Flex
            flexDir={["column", "column", "row"]}
            gap={5}
            justify="space-between"
          >
            <Text w="50%">
              <strong>THE TRAIBLAZER: </strong>
              {data?.theTrailblazer ? data?.theTrailblazer : "Empty"}
            </Text>
            <Text w="50%">
              <strong>UNALIGNED: </strong>
              {data?.unaligned ? data?.unaligned : "Empty"}
            </Text>
          </Flex>
          <Flex
            flexDir={["column", "column", "row"]}
            gap={5}
            justify="space-between"
          >
            <Text w="50%">
              <strong>PAGE TITLE: </strong>
              {data?.pageTitle ? data?.pageTitle : "Empty"}
            </Text>
            <Text w="50%">
              <strong>PAGE DESC: </strong>
              {data?.purposeDescription ? data?.purposeDescription : "Empty"}
            </Text>
          </Flex>
          <Flex
            flexDir={["column", "column", "row"]}
            gap={5}
            justify="space-between"
          >
            <Text w="50%">
              <strong>PAGE PURPOSE: </strong>{" "}
              {data?.pagePurpose ? data?.pagePurpose : "Empty"}
            </Text>
            <Text w="50%">
              <strong>PAGE SUB-TITLE: </strong>
              {data?.pageSubtitle ? data?.pageSubtitle : "Empty"}
            </Text>
          </Flex>
        </VStack>

        <HStack justify="space-between" w="full" py={5}>
          <Button
            as={Link}
            to="/dashboard/report"
            leftIcon={<BsArrowLeftCircle />}
            fontSize="16px"
            fontWeight="400"
            rounded="6px"
          >
            Back
          </Button>
          <Button
            type="submit"
            fontSize="16px"
            fontWeight="400"
            bg="#FF6633"
            color="white"
            rounded="6px"
            width="130px"
            _hover={{ bg: "#fb4509", transition: "ease 0.3s all" }}
          >
            Update
          </Button>
        </HStack>
      </VStack>
    </Flex>
  );
};

export default View;
