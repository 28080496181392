import { Box, Flex, HStack, Img, Text, VStack } from "@chakra-ui/react";
import moment from "moment";
import { useContext } from "react";
import logo from "../../../assets/img/LOGO.png";
import InputContext from "../../../context/ReportContext/ReportContext";
import { ReportData } from "../../../utils/types";

interface ISummery {
  reportData?: any;
}

const Summery: React.FC<ISummery> = ({ reportData }) => {
  const { inputState } = useContext(InputContext);
  const inputData = inputState?.userInputs;

  return (
    <Box
      h={"auto"}
      className="page-break"
      overflow="hidden"
      w="full"
      position="relative"
    >
      <Box p={[1, 3]}>
        <HStack justify="space-between" align="center" pb={10} w="full">
          <Flex align="left" justify="left" w="50%">
            <Img src={logo} w="24rem" h="auto" p={0} alt="LOGO" />
          </Flex>
          <Text
            w="50%"
            textTransform="uppercase"
            fontWeight="bold"
            fontSize="17px"
            color="gray.500"
            align="right"
          >
            {moment().format("D MMM, YYYY")}
            {inputData?.name ? <span>{" - " + inputData?.name}</span> : null}
          </Text>
        </HStack>
        <VStack spacing={[6, 10]} textAlign="left">
          {reportData &&
            reportData.map((data: ReportData, index: number) => {
              if (
                (data.pageName && data.GNPN === "G1N1") ||
                data.GNPN === "G1N2" ||
                data.GNPN === "G1N3" ||
                data.GNPN === "G1N4" ||
                data.GNPN === "G1N5" ||
                data.GNPN === "G1N6" ||
                data.GNPN === "G1N7" ||
                data.GNPN === "G1N8" ||
                data.GNPN === "G1N9" ||
                data.GNPN === "G1N10" ||
                data.GNPN === "G1N11" ||
                data.GNPN === "G1N12"
              )
                return (
                  <>
                    <Text
                      w="full"
                      textAlign="left"
                      textTransform="uppercase"
                      fontWeight="bold"
                      fontSize="17px"
                      color="gray.600"
                    >
                      You are {data?.pageName}
                    </Text>
                    <Text
                      w="full"
                      textAlign="left"
                      textTransform="uppercase"
                      fontWeight="bold"
                      fontSize="17px"
                      color="gray.600"
                    >
                      Your soul purpose is {data?.pagePurpose}
                    </Text>
                  </>
                );
              if (
                (data.downBoldText && data.GNPN === "G3N1") ||
                data.GNPN === "G3N2" ||
                data.GNPN === "G3N3" ||
                data.GNPN === "G3N4" ||
                data.GNPN === "G3N5" ||
                data.GNPN === "G3N6" ||
                data.GNPN === "G3N7" ||
                data.GNPN === "G3N8" ||
                data.GNPN === "G3N9" ||
                data.GNPN === "G3N10" ||
                data.GNPN === "G3N11" ||
                data.GNPN === "G3N12"
              )
                return (
                  <>
                    {inputData?.charts === "CHART_ONE" && data?.pageName ? (
                      <>
                        <Text
                          w="full"
                          textAlign="left"
                          textTransform="uppercase"
                          fontWeight="bold"
                          fontSize="17px"
                          color="gray.600"
                        >
                          IN YOUR ELEMENT you are {data?.pageName}
                        </Text>

                        <Text
                          w="full"
                          textAlign="left"
                          textTransform="uppercase"
                          fontWeight="bold"
                          fontSize="17px"
                          color="gray.600"
                        >
                          {data?.downBoldText}
                        </Text>
                      </>
                    ) : inputData?.charts === "CHART_TWO" &&
                      data?.downBoldText ? (
                      <>
                        <Text
                          w="full"
                          textAlign="left"
                          textTransform="uppercase"
                          fontWeight="bold"
                          fontSize="17px"
                          color="gray.600"
                        >
                          you carry the {data?.downBoldText}
                        </Text>
                      </>
                    ) : null}
                    {/* </Text> */}
                  </>
                );
              if (
                (data.pageName && data.GNPN === "G4N1") ||
                data?.GNPN === "G4N2" ||
                data?.GNPN === "G4N3" ||
                data?.GNPN === "G4N4" ||
                data?.GNPN === "G4N5" ||
                data?.GNPN === "G4N6" ||
                data?.GNPN === "G4N7" ||
                data?.GNPN === "G4N8" ||
                data?.GNPN === "G4N9" ||
                data?.GNPN === "G4N10" ||
                data?.GNPN === "G4N11" ||
                data?.GNPN === "G4N12"
              )
                return (
                  <>
                    <Text
                      w="full"
                      textAlign="left"
                      textTransform="uppercase"
                      fontWeight="bold"
                      fontSize="17px"
                      color="gray.600"
                    >
                      your energy shows up as {data?.pageName}
                    </Text>
                    <Text
                      w="full"
                      textAlign="left"
                      textTransform="uppercase"
                      fontWeight="bold"
                      fontSize="17px"
                      color="gray.600"
                    >
                      aligned you are {data?.aligned}
                    </Text>
                  </>
                );
              if (
                (data.pageName && data.GNPN === "G5N1") ||
                data?.GNPN === "G5N2" ||
                data?.GNPN === "G5N3" ||
                data?.GNPN === "G5N4" ||
                data?.GNPN === "G5N5" ||
                data?.GNPN === "G5N6" ||
                data?.GNPN === "G5N7" ||
                data?.GNPN === "G5N8" ||
                data?.GNPN === "G5N9" ||
                data?.GNPN === "G5N10" ||
                data?.GNPN === "G5N11" ||
                data?.GNPN === "G5N12"
              )
                return (
                  <Text
                    w="full"
                    textAlign="left"
                    textTransform="uppercase"
                    fontWeight="bold"
                    fontSize="17px"
                    color="gray.600"
                  >
                    When triggered {data?.pageName}
                  </Text>
                );
              if (
                (data.downBoldText && data.GNPN === "G6N1") ||
                data?.GNPN === "G6N2" ||
                data?.GNPN === "G6N3" ||
                data?.GNPN === "G6N4" ||
                data?.GNPN === "G6N5" ||
                data?.GNPN === "G6N6" ||
                data?.GNPN === "G6N7" ||
                data?.GNPN === "G6N8" ||
                data?.GNPN === "G6N9" ||
                data?.GNPN === "G6N10" ||
                data?.GNPN === "G6N11" ||
                data?.GNPN === "G6N12"
              )
                return (
                  <Text
                    w="full"
                    textAlign="left"
                    textTransform="uppercase"
                    fontWeight="bold"
                    fontSize="17px"
                    color="gray.600"
                  >
                    {data?.downBoldText}
                  </Text>
                );

              if (
                (data.pagePurpose && data.GNPN === "G7N1") ||
                data.GNPN === "G7N2" ||
                data.GNPN === "G7N3" ||
                data.GNPN === "G7N4" ||
                data.GNPN === "G7N5" ||
                data.GNPN === "G7N6" ||
                data.GNPN === "G7N7" ||
                data.GNPN === "G7N8" ||
                data.GNPN === "G7N9" ||
                data.GNPN === "G7N10" ||
                data.GNPN === "G7N11" ||
                data.GNPN === "G7N12"
              )
                return (
                  <Text
                    w="full"
                    textAlign="left"
                    textTransform="uppercase"
                    fontWeight="bold"
                    fontSize="17px"
                    color="gray.600"
                  >
                    {data?.pagePurpose}
                  </Text>
                );
              if (
                (data.pageName && data.GNPN === "G8N1") ||
                data?.GNPN === "G8N2" ||
                data?.GNPN === "G8N3" ||
                data?.GNPN === "G8N4" ||
                data?.GNPN === "G8N5" ||
                data?.GNPN === "G8N6" ||
                data?.GNPN === "G8N7" ||
                data?.GNPN === "G8N8" ||
                data?.GNPN === "G8N9" ||
                data?.GNPN === "G8N10" ||
                data?.GNPN === "G8N11" ||
                data?.GNPN === "G8N12"
              )
                return (
                  <Text
                    w="full"
                    textAlign="left"
                    textTransform="uppercase"
                    fontWeight="bold"
                    fontSize="17px"
                    color="gray.600"
                  >
                    {data?.pageName}
                  </Text>
                );

              if (
                (data.pageName && data.GNPN === "G9N1") ||
                data?.GNPN === "G9N2" ||
                data?.GNPN === "G9N3" ||
                data?.GNPN === "G9N4" ||
                data?.GNPN === "G9N5" ||
                data?.GNPN === "G9N6" ||
                data?.GNPN === "G9N7" ||
                data?.GNPN === "G9N8" ||
                data?.GNPN === "G9N9" ||
                data?.GNPN === "G9N10" ||
                data?.GNPN === "G9N11" ||
                data?.GNPN === "G9N12"
              )
                return (
                  <Text
                    w="full"
                    textAlign="left"
                    textTransform="uppercase"
                    fontWeight="bold"
                    fontSize="17px"
                    color="gray.600"
                  >
                    {data?.pageName}
                  </Text>
                );
            })}
        </VStack>
      </Box>
    </Box>
  );
};

export default Summery;
