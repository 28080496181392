import {
  Button,
  ButtonGroup,
  Container,
  Spinner,
  VStack,
} from "@chakra-ui/react";
import React, { useContext, useRef, useState } from "react";
import toast from "react-hot-toast";
import { BsPrinterFill } from "react-icons/bs";
import { IoIosArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import GridDataContext from "../../context/Grid/Grid";
import InputContext from "../../context/ReportContext/ReportContext";
import Report3ChartTwo from "../Foruser/ReportPage/ChartTwo/Report3ChartTwo";
import Report6ChartTwo from "../Foruser/ReportPage/ChartTwo/Report6ChartTwo";
import Report3 from "../Foruser/ReportPage/Report3";
import Report4 from "../Foruser/ReportPage/Report4";
import Report5 from "../Foruser/ReportPage/Report5";
import Report6 from "../Foruser/ReportPage/Report6";
import Report7 from "../Foruser/ReportPage/Report7";
import Report8 from "../Foruser/ReportPage/Report8";
import Report9 from "../Foruser/ReportPage/Report9";
import ReportFor1_2 from "../Foruser/ReportPage/ReportFor1_2";
import Summery from "../Foruser/ReportPage/Summery";
import UserInformation from "../Foruser/ReportPage/UserInformation";

const Export = () => {
  window.document.title = "SOUL BLUEPRINT ALIGNMENT - Export report";
  const navigate = useNavigate();
  const { inputState } = useContext(InputContext);
  const { gridState } = useContext(GridDataContext);
  const [loading, setLoading] = useState<boolean>(false);

  const reportData: any = gridState.gridData;

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current as any,
    documentTitle: "Soul Blueprint",
    removeAfterPrint: true,
    onBeforeGetContent: () => setLoading(true),
    onPrintError: () => {
      setLoading(false);
      toast.error("PDF not save!");
    },
    onAfterPrint: () => {
      setLoading(false);
      toast.success("PDF save successfully🚀");
    },
  });
  const chart = inputState?.userInputs?.charts;

  return (
    <React.Fragment>
      <Container
        maxW="full"
        w={["full", "full", "1080px"]}
        h="full"
        my="5"
        ref={componentRef as any}
      >
        <VStack spacing={[7, 10]}>
          <UserInformation
            data={inputState && inputState?.userInputs}
            reportData={reportData}
          />
          {!reportData
            ? window.location.replace("/input")
            : reportData?.map((item: any, index: any) =>
                item?.GNPN === "G1N1" ||
                item?.GNPN === "G1N2" ||
                item?.GNPN === "G1N3" ||
                item?.GNPN === "G1N4" ||
                item?.GNPN === "G1N5" ||
                item?.GNPN === "G1N6" ||
                item?.GNPN === "G1N7" ||
                item?.GNPN === "G1N8" ||
                item?.GNPN === "G1N9" ||
                item?.GNPN === "G1N10" ||
                item?.GNPN === "G1N11" ||
                item?.GNPN === "G1N12" ||
                item?.GNPN === "G2N1" ||
                item?.GNPN === "G2N2" ||
                item?.GNPN === "G2N3" ||
                item?.GNPN === "G2N4" ||
                item?.GNPN === "G2N5" ||
                item?.GNPN === "G2N6" ||
                item?.GNPN === "G2N7" ||
                item?.GNPN === "G2N8" ||
                item?.GNPN === "G2N9" ||
                item?.GNPN === "G2N10" ||
                item?.GNPN === "G2N11" ||
                item?.GNPN === "G2N12" ? (
                  <ReportFor1_2 item={item} key={index} />
                ) : item?.GNPN === "G3N1" ||
                  item?.GNPN === "G3N2" ||
                  item?.GNPN === "G3N3" ||
                  item?.GNPN === "G3N4" ||
                  item?.GNPN === "G3N5" ||
                  item?.GNPN === "G3N6" ||
                  item?.GNPN === "G3N7" ||
                  item?.GNPN === "G3N8" ||
                  item?.GNPN === "G3N9" ||
                  item?.GNPN === "G3N10" ||
                  item?.GNPN === "G3N11" ||
                  item?.GNPN === "G3N12" ? (
                  <>
                    {chart === "CHART_TWO" ? (
                      <Report3ChartTwo item={item} key={index} />
                    ) : (
                      <Report3 item={item} key={index} />
                    )}
                  </>
                ) : item?.GNPN === "G4N1" ||
                  item?.GNPN === "G4N2" ||
                  item?.GNPN === "G4N3" ||
                  item?.GNPN === "G4N4" ||
                  item?.GNPN === "G4N5" ||
                  item?.GNPN === "G4N6" ||
                  item?.GNPN === "G4N7" ||
                  item?.GNPN === "G4N8" ||
                  item?.GNPN === "G4N9" ||
                  item?.GNPN === "G4N10" ||
                  item?.GNPN === "G4N11" ||
                  item?.GNPN === "G4N12" ? (
                  <Report4 item={item} key={index} />
                ) : item?.GNPN === "G5N1" ||
                  item?.GNPN === "G5N2" ||
                  item?.GNPN === "G5N3" ||
                  item?.GNPN === "G5N4" ||
                  item?.GNPN === "G5N5" ||
                  item?.GNPN === "G5N6" ||
                  item?.GNPN === "G5N7" ||
                  item?.GNPN === "G5N8" ||
                  item?.GNPN === "G5N9" ||
                  item?.GNPN === "G5N10" ||
                  item?.GNPN === "G5N11" ||
                  item?.GNPN === "G5N12" ? (
                  <Report5 item={item} key={index} />
                ) : item?.GNPN === "G6N1" ||
                  item?.GNPN === "G6N2" ||
                  item?.GNPN === "G6N3" ||
                  item?.GNPN === "G6N4" ||
                  item?.GNPN === "G6N5" ||
                  item?.GNPN === "G6N6" ||
                  item?.GNPN === "G6N7" ||
                  item?.GNPN === "G6N8" ||
                  item?.GNPN === "G6N9" ||
                  item?.GNPN === "G6N10" ||
                  item?.GNPN === "G6N11" ||
                  item?.GNPN === "G6N12" ? (
                  <>
                    {chart === "CHART_TWO" ? (
                      <Report6ChartTwo item={item} key={index} />
                    ) : (
                      <Report6 item={item} key={index} />
                    )}
                  </>
                ) : item?.GNPN === "G7N1" ||
                  item?.GNPN === "G7N2" ||
                  item?.GNPN === "G7N3" ||
                  item?.GNPN === "G7N4" ||
                  item?.GNPN === "G7N5" ||
                  item?.GNPN === "G7N6" ||
                  item?.GNPN === "G7N7" ||
                  item?.GNPN === "G7N8" ||
                  item?.GNPN === "G7N9" ||
                  item?.GNPN === "G7N10" ||
                  item?.GNPN === "G7N11" ||
                  item?.GNPN === "G7N12" ? (
                  <Report7 item={item} key={index} />
                ) : item?.GNPN === "G8N1" ||
                  item?.GNPN === "G8N2" ||
                  item?.GNPN === "G8N3" ||
                  item?.GNPN === "G8N4" ||
                  item?.GNPN === "G8N5" ||
                  item?.GNPN === "G8N6" ||
                  item?.GNPN === "G8N7" ||
                  item?.GNPN === "G8N8" ||
                  item?.GNPN === "G8N9" ||
                  item?.GNPN === "G8N10" ||
                  item?.GNPN === "G8N11" ||
                  item?.GNPN === "G8N12" ? (
                  <Report8 item={item} key={index} />
                ) : item?.GNPN === "G9N1" ||
                  item?.GNPN === "G9N2" ||
                  item?.GNPN === "G9N3" ||
                  item?.GNPN === "G9N4" ||
                  item?.GNPN === "G9N5" ||
                  item?.GNPN === "G9N6" ||
                  item?.GNPN === "G9N7" ||
                  item?.GNPN === "G9N8" ||
                  item?.GNPN === "G9N9" ||
                  item?.GNPN === "G9N10" ||
                  item?.GNPN === "G9N11" ||
                  item?.GNPN === "G9N12" ? (
                  <Report9 item={item} key={index} />
                ) : null
              )}
          <Summery reportData={reportData} />
        </VStack>
      </Container>
      <ButtonGroup
        my={10}
        w="full"
        justifyContent="center"
        variant={"ghost"}
        spacing={3}
      >
        <Button
          fontSize="lg"
          fontWeight="semibold"
          variant="solid"
          colorScheme="whatsapp"
          leftIcon={<IoIosArrowBack />}
          onClick={() => navigate("/")}
        >
          Back
        </Button>
        <Button
          fontSize="lg"
          fontWeight="semibold"
          variant="solid"
          textAlign="center"
          colorScheme="twitter"
          onClick={handlePrint}
          leftIcon={<BsPrinterFill />}
        >
          {loading ? <Spinner /> : "Print"}
        </Button>
      </ButtonGroup>
    </React.Fragment>
  );
};

export default Export;
