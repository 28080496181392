import { createContext } from "react";

export interface INumber {
  numberOne: number;
  numberTwo: number;
  numberThree: number;
  numberFour: number;
  numberFive: number;
  numberSix: number;
  numberSeven: number;
  numberSevenTwo: number;
  numberEight: number;
  numberEightTwo: number;
  numberNine: number;
  numberNineTwo: number;
}

export interface INumberContextState {
  numbers: INumber | undefined;
}

export const defaultContextState: INumberContextState = {
  numbers: undefined,
};

export type TNumberContextAction = "set_number" | "unset_number";

export type TNumberContextPayload = INumber | undefined;

export interface INumberContextAction {
  type: TNumberContextAction;
  payload: TNumberContextPayload;
}

export const NumberReducer = (
  state: INumberContextState,
  action: INumberContextAction
) => {
  switch (action.type) {
    case "set_number": {
      return { ...state, numbers: action.payload as INumber };
    }
    case "unset_number": {
      return { ...state, numbers: undefined as undefined };
    }
    default:
      return { ...state };
  }
};

interface INumberContextProps {
  numberState: INumberContextState;
  numberDispatch: React.Dispatch<INumberContextAction>;
}

const NumberContext = createContext<INumberContextProps>({
  numberState: defaultContextState,
  numberDispatch: () => {},
});

export const NumberContextConsumer = NumberContext.Consumer;
export const NumberContextProvider = NumberContext.Provider;

export default NumberContext;
