import { Button, Center, Container } from "@chakra-ui/react";
import { Toaster } from "react-hot-toast";
import { Navigate, Outlet } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import { axiosPublic } from "../utils/axios";

const UserLayout = () => {
  const { session, unsetAuth } = useAuth();
  // const { colorMode } = useColorMode();

  const logout = async () => {
    unsetAuth();
    await axiosPublic.post("/api/auth/logout");
    window.location.reload();
  };
  return session?.accessToken && session?.user?.role === "CLIENT" ? (
    <Container maxW="4xl" overflow="hidden">
      <Button
        type="button"
        onClick={logout}
        position="absolute"
        right={3}
        top={3}
        colorScheme="red"
        size="lg"
      >
        Log out
      </Button>
      <Toaster position="top-center" reverseOrder={false} />
      <Center
        w="full"
        h="auto"
        // bg={colorMode === "dark" ? "gray.600" : "unset"}
      >
        <Outlet />
      </Center>
    </Container>
  ) : session?.accessToken && session?.user.role === "ADMIN" ? (
    <Navigate to="/dashboard" />
  ) : (
    <Navigate to="/login" />
  );
};

export default UserLayout;
