import {
  Button,
  Center,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Spinner,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import { axiosPublic } from "../utils/axios";
import { defaultValues } from "../utils/defualtValue";

const Login = () => {
  window.document.title = "SOUL BLUEPRINT ALIGNMENT - Login";
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const { setAuth } = useAuth();
  const navigate = useNavigate();
  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    try {
      const { data } = await axiosPublic.post("/api/auth/login", {
        username,
        password,
      });

      setAuth({ accessToken: data?.accessToken, user: data?.user });
      toast.success(data?.message);
      data?.user.role === "ADMIN" ? navigate("/dashboard") : navigate("/");
    } catch (error: any) {
      if (error) {
        const mess = error?.response?.data;
        toast.error(mess?.message);
      }
    } finally {
      setLoading(false);
    }
  };
  return (
    <Center w="full" h="100vh">
      <Stack
        spacing={0}
        rounded="lg"
        w="md"
        align="center"
        p={[3, 7]}
        boxShadow="lg"
      >
        <Heading pb={[4, 7]}>Login</Heading>

        <form style={{ width: "100%" }} onSubmit={onSubmit}>
          <VStack py={3} spacing={3} w="full">
            <FormControl isRequired>
              <FormLabel htmlFor="username">Username</FormLabel>
              <Input
                size="md"
                id="username"
                type="text"
                placeholder="Username"
                borderBottomColor="gray.200"
                variant="flushed"
                px="2"
                w="full"
                onChange={(e: any) => setUsername(e.target.value)}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel htmlFor="password">Password</FormLabel>
              <Input
                size="md"
                type="password"
                placeholder="Password"
                borderBottomColor="gray.200"
                variant="flushed"
                px="2"
                id="password"
                w="full"
                onChange={(e: any) => setPassword(e.target.value)}
              />
            </FormControl>
            <VStack w="full" spacing={0} pt={[2, 4]}>
              <Button
                w="full"
                variant="solid"
                colorScheme="orange"
                type="submit"
                mb={2}
              >
                {loading ? <Spinner /> : "Sign in"}
              </Button>
              <Text>Forgot password?</Text>
              <Link
                to="/reset-password"
                style={{ textDecoration: "underline", color: "red" }}
              >
                Click here
              </Link>
            </VStack>
          </VStack>
        </form>
      </Stack>
    </Center>
  );
};

export default Login;
