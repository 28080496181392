import { Text } from "@chakra-ui/react";
import "./print.css";

interface IForGridOne {
  bg?: string;
}

const Copywring = () => {
  return (
    <Text
      id="watermark"
      fontSize="13px"
      textAlign="center"
      p={2}
      color="blackAlpha.900"
      bg="transparent"
      position="fixed"
      bottom="0"
      fontWeight="light"
    >
      Licensed and copyright protected to The Bliss Academy Pty Ltd. All rights
      reserved © 2023. No part of this document may be reproduced of transmitted
      in any form or by any means without prior permission
    </Text>
  );
};

export default Copywring;

export const CopywringWithBg = ({ bg }: IForGridOne) => {
  return (
    <Text
      w="full"
      // bg={bg}
      id="watermark"
      fontSize="13px"
      textAlign="center"
      p={2}
      position="fixed"
      bottom="0"
    >
      Licensed and copyright protected to The Bliss Academy Pty Ltd. All rights
      reserved © 2023. No part of this document may be reproduced of transmitted
      in any form or by any means without prior permission
    </Text>
  );
};
