import { useState } from "react";

const useFormatTime = () => {
  const [time, setTime] = useState<any>("");
  const formatTime = (value: string) => {
    let timeSplit = value.split(":"),
      hours,
      minutes,
      meridian;
    hours = Number(timeSplit[0]);
    minutes = Number(timeSplit[1]);

    if (hours > 12) {
      meridian = "PM";
      hours -= 12;
    } else if (hours < 12) {
      meridian = "AM";
      if (hours === 0) {
        hours = 12;
      }
    } else {
      meridian = "PM";
    }
    setTime(hours + ":" + minutes + " " + meridian);
  };
  return {
    formatTime,
    time,
  };
};

export default useFormatTime;
