import {
  Box,
  Divider,
  Flex,
  Heading,
  HStack,
  Image,
  Text,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import Copyright from "../../../components/Sm/Copyright";
import Grid from "../../../components/Sm/Grid";

interface IReport {
  item?: any;
}

const Report3: React.FC<IReport> = ({ item }) => {
  console.log(item?.pageDescription);
  return (
    <Box
      className="page-break"
      h={"auto"}
      overflow="hidden"
      w="full"
      position="relative"
    >
      <HStack align="center" justify="center" w="full" pos="relative">
        <Copyright />
        <Grid text={item?.GNPN} />
      </HStack>
      <Flex w="full" justify="center" align="center" gap={8}>
        <Image
          src={`/uploads/${item?.logoOne}`}
          w={["14", "20"]}
          h={["14", "20"]}
          alt="image"
        />
        <VStack spacing={[0, 1]}>
          <Divider borderColor="gray.200" />
          <Text fontSize={["14px", "16px"]} fontWeight="bold">
            {item?.pageNumber}
          </Text>
          <Heading
            fontSize={["15px", "18px"]}
            fontWeight="400"
            textTransform="uppercase"
          >
            {item?.pageTitle}
          </Heading>
          <Divider borderColor="gray.200" />
          <Heading
            fontSize={["17px", "20px"]}
            pt={[2, 3]}
            pb={[1, 2]}
            textTransform="uppercase"
          >
            {item?.pageName}
          </Heading>
        </VStack>
        <Image
          src={`/uploads/${item?.logoTwo}`}
          w={["24", "20"]}
          h={["24", "20"]}
          alt="image"
        />
      </Flex>
      {/* heading */}
      <VStack
        align="left"
        textAlign="left"
        justify="center"
        spacing={[4, 8]}
        w="full"
      >
        <div
          style={{ width: "100%", marginTop: "15px", marginBottom: "15px" }}
          dangerouslySetInnerHTML={{ __html: item?.pageDescription }}
        ></div>
        <Heading
          fontSize={["16px", "20px"]}
          fontWeight="600"
          textTransform="uppercase"
          textAlign="left"
        >
          {item?.downBoldText}
        </Heading>
      </VStack>
    </Box>
  );
};

export default Report3;
