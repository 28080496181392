import { useState } from "react";
import { toast } from "react-hot-toast";
import { axiosPublic } from "../utils/axios";

const useFileUpload = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState<any>("");
  const [data, setDate] = useState<string>("");
  const upload = async (files: FileList | null) => {
    if (!files) return setError("file not found");
    try {
      setSuccess(false);
      setLoading(true);
      const formDate = new FormData();
      formDate.append("file", files[0]);
      const res = await axiosPublic.post("/api/upload", formDate);
      toast.success("Image uploaded!");
      setDate(res.data);
      setSuccess(true);
    } catch (error: any) {
      toast.error("Image upload fail!");
      if (!error?.response) return setError("Server not responding....");
      setSuccess(false);
      setError(error.response.data.message);
    } finally {
      setSuccess(true);
      setLoading(false);
    }
  };
  return {
    loading,
    success,
    data,
    error,
    upload,
  };
};

export default useFileUpload;
