import React, { PropsWithChildren, useReducer } from "react";

import {
  defaultContextState,
  GridDataContextProvider,
  GridDataReducer,
} from "./Grid";

export interface IGridDataContextComponentProps extends PropsWithChildren {}

const GridDataContextComponent: React.FunctionComponent<
  IGridDataContextComponentProps
> = ({ children }) => {
  const [gridState, gridDispatch] = useReducer(
    GridDataReducer,
    defaultContextState
  );
  return (
    <GridDataContextProvider value={{ gridState, gridDispatch }}>
      {children}
    </GridDataContextProvider>
  );
};

export default GridDataContextComponent;
