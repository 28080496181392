import { Box, Heading, HStack, VStack } from "@chakra-ui/react";
import Copyright from "../../../components/Sm/Copyright";
import Grid from "../../../components/Sm/Grid";

interface IReport {
  item?: any;
}

const Report9: React.FC<IReport> = ({ item }) => {
  return (
    <Box
      h="auto"
      className="page-break"
      overflow="hidden"
      w="full"
      position="relative"
    >
      <HStack align="center" justify="center" w="full" pos="relative">
        <Copyright />
        <Grid text={item?.GNPN} />
      </HStack>
      <VStack align="left" textAlign="left">
        <HStack spacing={[2, 3]} align="center">
          <Box
            w={["10", "14"]}
            h={["10", "14"]}
            bg={`${item?.box_color}`}
          ></Box>
          <Heading fontSize={["16px", "25px"]} textTransform="uppercase">
            {item?.pageTitle}
          </Heading>
        </HStack>
        <Heading
          fontSize={["17px", "20px"]}
          pt={[2, 3]}
          pb={[1, 2]}
          textTransform="uppercase"
        >
          {item?.pageName}
        </Heading>
        <div
          style={{ width: "100%", marginTop: "15px", marginBottom: "15px" }}
          dangerouslySetInnerHTML={{ __html: item?.pageDescription }}
        ></div>
      </VStack>
    </Box>
  );
};

export default Report9;
