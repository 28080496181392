import {
  Box,
  Flex,
  Heading,
  HStack,
  Image,
  ListItem,
  UnorderedList,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import Copyright from "../../../../components/Sm/Copyright";
import Grid from "../../../../components/Sm/Grid";

interface IReport {
  item?: any;
}

const Report3ChartTwo: React.FC<IReport> = ({ item }) => {
  const remedyList = item?.remedy.split(";");
  const woundListList = item?.woundList.split(";");
  return (
    <Box
      className="page-break"
      h="auto"
      overflow="hidden"
      w="full"
      position="relative"
    >
      <HStack align="center" justify="center" w="full" pos="relative">
        <Copyright />
        <Grid text={item?.GNPN} />
      </HStack>
      <VStack align="left" py={[5, 10]} gap={[3, 5]}>
        <Flex justify="space-between" align="center" pb={[2, 3]}>
          <HStack spacing={[2, 3]}>
            <Box
              w={["10", "14"]}
              h={["10", "14"]}
              bg={`${item?.box_color}`}
            ></Box>
            <Heading fontSize={["19px", "25px"]} textTransform="uppercase">
              {item?.pageName}
            </Heading>
          </HStack>
          <Image
            src={`/uploads/${item?.logoOne ? item?.logoOne : item?.logoTwo}`}
            w={["16", "20"]}
            h={["16", "20"]}
            alt="image"
          />
        </Flex>
        <VStack spacing={6} w="full" align="left">
          <VStack align="left">
            <Heading
              textTransform="uppercase"
              fontWeight="500"
              fontSize={["15px", "17px"]}
            >
              Wound:
            </Heading>
            <UnorderedList pl={[4, 7]}>
              {woundListList.map((i: any, index: any) => (
                <ListItem key={index}>{i}</ListItem>
              ))}
            </UnorderedList>
          </VStack>
          <VStack align="left" textAlign="left">
            <Heading
              textTransform="uppercase"
              fontWeight="500"
              fontSize={["15px", "17px"]}
            >
              Remedy:
            </Heading>
            <UnorderedList pl={[4, 7]}>
              {remedyList.map((i: any, index: any) => (
                <ListItem key={index}>{i}</ListItem>
              ))}
            </UnorderedList>
          </VStack>
          <Heading
            fontSize={["16px", "20px"]}
            fontWeight="600"
            textTransform="uppercase"
            textAlign="center"
          >
            {item?.downBoldText}
          </Heading>
        </VStack>
      </VStack>
    </Box>
  );
};

export default Report3ChartTwo;
