import React, { PropsWithChildren, useReducer } from "react";

import {
  InputContextProvider,
  InputReducer,
  defaultContextState,
} from "./ReportContext";

export interface IInputContextComponentProps extends PropsWithChildren {}

const InputContextComponent: React.FunctionComponent<
  IInputContextComponentProps
> = ({ children }) => {
  const [inputState, inputDispatch] = useReducer(
    InputReducer,
    defaultContextState
  );
  return (
    <InputContextProvider value={{ inputState, inputDispatch }}>
      {children}
    </InputContextProvider>
  );
};

export default InputContextComponent;
