import React, { PropsWithChildren, useReducer } from "react";

import {
  AuthContextProvider,
  AuthReducer,
  defaultAuthContextState,
} from "./AuthContext";

export interface IAuthContextComponentProps extends PropsWithChildren {}

const AuthContextComponent: React.FunctionComponent<
  IAuthContextComponentProps
> = ({ children }) => {
  const [authState, authDispatch] = useReducer(
    AuthReducer,
    defaultAuthContextState
  );
  return (
    <AuthContextProvider value={{ authState, authDispatch }}>
      {children}
    </AuthContextProvider>
  );
};

export default AuthContextComponent;
