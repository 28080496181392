import { BrowserRouter, Route, Routes } from "react-router-dom";
import NotFound from "./components/common/NotFound";
import PersistLogin from "./components/PersistLogin";
import UserViews from "./components/views/UserViews";
import View from "./components/views/View";
import AuthContextComponent from "./context/AuthContext/AuthContextCompoent";
import GridDataContextComponent from "./context/Grid/GridComponent";
import InputContextComponent from "./context/ReportContext/ReportContextComponet";
import NumberContextComponent from "./context/SmNumberContext/NumberComponent";
import AuthLayout from "./layouts/AuthLayout";
import MainLayout from "./layouts/MainLayout";
import UserLayout from "./layouts/UserLayout";
import ChartData from "./pages/Chart/ChartData";
import Dashboard from "./pages/Dashboard";
import Export from "./pages/Export/Export";
import Chart from "./pages/Foruser/Chart";
import InputNumber from "./pages/Foruser/InputNumber";
import Login from "./pages/Login";
import Profile from "./pages/Profile/Profile";
import NewReport from "./pages/Reports/NewReport";
import Report from "./pages/Reports/Report";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import SetPasswordPage from "./pages/ResetPassword/SetPasswordPage";
import NewUser from "./pages/users/NewUser";
import Users from "./pages/users/Users";

function App() {
  window.document.title = "SOUL BLUEPRINT ALIGNMENT - App";
  console.log("%c SOUL BLUEPRINT ALIGNMENT", "color: green; font-size: 20px");
  return (
    <>
      <BrowserRouter basename="/">
        <AuthContextComponent>
          <GridDataContextComponent>
            <InputContextComponent>
              <NumberContextComponent>
                <Routes>
                  <Route element={<PersistLogin />}>
                    <Route element={<MainLayout />} path="/dashboard">
                      <Route element={<Dashboard />} index />
                      <Route path="users">
                        <Route element={<Users />} index />
                        <Route element={<NewUser />} path="create" />
                        <Route element={<UserViews />} path="view/:id" />
                      </Route>

                      <Route path="report">
                        <Route element={<Report />} index />
                        <Route element={<NewReport />} path="create" />
                        <Route element={<View />} path="view/:id" />
                      </Route>
                      <Route path="chart-data">
                        <Route element={<ChartData />} index />
                      </Route>
                      <Route path="profile">
                        <Route element={<Profile />} index />
                      </Route>
                    </Route>
                    <Route element={<UserLayout />} path="/">
                      <Route index element={<Chart />} />
                      <Route path="input" element={<InputNumber />} />
                    </Route>
                    <Route path="export" element={<Export />} />
                    <Route element={<AuthLayout />}>
                      <Route element={<Login />} path="login" />
                    </Route>
                  </Route>
                  <Route path="reset-password" element={<ResetPassword />} />
                  <Route
                    path="set-password/:id/:token"
                    element={<SetPasswordPage />}
                  />
                  <Route path="*" element={<NotFound />} />
                </Routes>
              </NumberContextComponent>
            </InputContextComponent>
          </GridDataContextComponent>
        </AuthContextComponent>
      </BrowserRouter>
    </>
  );
}

export default App;
