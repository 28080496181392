import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Input,
  InputGroup,
  Select,
  Spinner,
  Stack,
  Text,
  Textarea,
  useColorMode,
  VStack,
} from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import "quill/dist/quill.snow.css";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { BsArrowLeftCircle } from "react-icons/bs";
import { useQuill } from "react-quilljs";
import { Link, useNavigate } from "react-router-dom";
import useAxios from "../../hooks/useAxios";
import useFileUpload from "../../hooks/useFileUpload";

const NewReport = () => {
  window.document.title = "SOUL BLUEPRINT ALIGNMENT - Create";
  const axios = useAxios();
  const { colorMode } = useColorMode();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { quill, quillRef } = useQuill(); // ay line ar karone require() not defined bole
  const [gridtype, setGridtype] = useState<string>("1");
  const [gridNumber, setGridNumber] = useState<string>("1");
  const [GNPN, setGNPN] = useState<string>("G1N1");
  const [chart, setChart] = useState<string>("CHART_ONE");
  const [pageTitle, setPageTitle] = useState<string>("");
  const [pageSubtitle, setPageSubtitle] = useState<string>("");
  const [pageName, setPageName] = useState<string>("");
  const [pageDescription, setPageDescription] = useState<string>("");
  const [downBoldText, setDownBoldText] = useState<string>("");
  const [pagePurpose, setPagePurpose] = useState<string>("");
  const [purposeDescription, setPurposeDescription] = useState<string>("");
  const [pageDesire, setPageDesire] = useState<string>("");
  const [aligned, setAligned] = useState<string>("");
  const [unaligned, setUnaligned] = useState<string>("");
  const [pageDoMoreOf, setPageDoMoreOf] = useState<string>("");
  const [whenAligned, setWhenAligned] = useState<string>("");
  const [whenUnaligned, setWhenUnaligned] = useState<string>("");
  const [pageLetGoOf, setPageLetGoOf] = useState<string>("");
  const [pageAvoid, setPageAvoid] = useState<string>("");
  const [pageChallenge, setPageChallenge] = useState<string>("");
  const [wound, setWound] = useState<string>("");
  const [remedy, setRemedy] = useState<string>("");
  const [box_color, setBox_color] = useState<string>("");
  const [bottom_box_color, setBottom_box_color] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  //file upload hooks
  const {
    loading: lLoading,
    success: lSuccess,
    data: leftSideLogo,
    upload: leftLogoUpload,
  } = useFileUpload();

  const {
    data: rightSideLogo,
    success: rSuccess,
    loading: rLoading,
    upload: rightLogoUpload,
  } = useFileUpload();

  /**
   * useEffect here start
   */
  useEffect(() => {
    setGNPN(`G${gridtype}N${gridNumber}`);
  }, [gridtype, gridNumber, setGNPN]);

  useEffect(() => {
    if (quill) {
      quill.on("text-change", () => {
        setPageDescription(quillRef.current.firstChild.innerHTML);
      });
    }
  }, [quill, quillRef, setGNPN, gridNumber, gridtype]);
  /**
   * useEffect here end
   */

  const insertData = async () => {
    // const pageDoMoreOfArray = convertStringToArray(pageDoMoreOf as string);
    // const pageLetGoOfArray = convertStringToArray(pageLetGoOf as string);
    // const pageChallengeArray = convertStringToArray(pageChallenge as string);
    // const whenAlignedArray = convertStringToArray(whenAligned as string);
    // const whenUnalignedArray = convertStringToArray(whenUnaligned as string);
    // const pageWoundList = convertStringToArray(wound as string);
    // const pageRemedyList = convertStringToArray(remedy as string);

    setLoading(true);
    const data = {
      GNPN,
      chart,
      logoOne: leftSideLogo,
      logoTwo: rightSideLogo,
      pageNumber: gridNumber,
      pageTitle,
      pageSubtitle,
      pageName,
      pageDescription,
      downBoldText,
      pagePurpose,
      purposeDescription,
      pageDesire,
      aligned,
      unaligned,
      pageDoMoreOf,
      pageLetGoOf,
      whenAligned,
      whenUnaligned,
      pageAvoid,
      pageChallenge,
      wound,
      remedy,
      bottom_box_color,
      box_color,
    };
    try {
      const res = await axios.post("/api/data/create", data);
      const mess = res?.data;
      toast.success(mess?.message);
      navigate("/dashboard/report");
      queryClient.resetQueries({ queryKey: ["reports"] });
      setLoading(false);
    } catch (error: any) {
      toast.error(error?.res?.data?.message);
      setLoading(false);
    }
  };

  return (
    <Flex
      bg={colorMode === "dark" ? "blackAlpha.300" : "white"}
      rounded="10px"
      boxShadow="0px 4px 6px rgba(94, 73, 155, 0.04)"
      flexDir="column"
      gap={6}
    >
      {/* page header */}
      <Stack
        borderBottom="1px"
        borderBottomColor="#a6aebb4d"
        w="full"
        p={[2, 5]}
      >
        <Heading fontSize="20px" fontWeight="500">
          Insert report data
        </Heading>
      </Stack>
      {/* from */}
      <VStack px={[2, 14]} pb={[2, 5]} spacing={[2, 4]}>
        {/* condition from here */}
        <HStack
          w="full"
          justify="space-between"
          flexDir={["column", "row"]}
          spacing={[2, 5]}
        >
          {/* for grid */}
          <Stack w="full">
            <FormControl isRequired>
              <FormLabel fontSize="16px" fontWeight="400">
                Select your grid
              </FormLabel>
              <Select onChange={(e) => setGridtype(e.target.value)}>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
              </Select>
            </FormControl>
          </Stack>
          {/* for chart */}
          <Stack w="full">
            <FormControl isRequired>
              <FormLabel fontSize="16px" fontWeight="400">
                Select your chart
              </FormLabel>
              <Select onChange={(e) => setChart(e.target.value)}>
                <option value="CHART_ONE">CHART_ONE</option>
                <option value="CHART_TWO">CHART_TWO</option>
              </Select>
            </FormControl>
          </Stack>
          {/* for page number */}
          <Stack w="full">
            <FormControl isRequired>
              <FormLabel fontSize="16px" fontWeight="400">
                Select your page number
              </FormLabel>
              <Select onChange={(e) => setGridNumber(e.target.value)}>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
                <option value="11">11</option>
                <option value="12">12</option>
              </Select>
            </FormControl>
          </Stack>
          {/* GNPN */}
          <Stack w="full">
            <FormControl>
              <FormLabel fontSize="16px" fontWeight="400">
                Grid Number & Page Number
              </FormLabel>
              <InputGroup>
                <Input
                  value={GNPN}
                  disabled
                  size="lg"
                  type="text"
                  color="black"
                  fontWeight="600"
                />
              </InputGroup>
            </FormControl>
          </Stack>
        </HStack>
        {gridtype === "1" || gridtype === "2" ? (
          <>
            <HStack
              w="full"
              justify="space-between"
              flexDir={["column", "row"]}
              spacing={[2, 5]}
            >
              <Stack w="full">
                <FormControl>
                  <FormLabel fontSize="16px" fontWeight="400">
                    Left side logo
                  </FormLabel>
                  <InputGroup>
                    {lLoading ? (
                      <Spinner />
                    ) : (
                      <>
                        {lSuccess ? (
                          <Text color="green.400">Image uploaded</Text>
                        ) : (
                          <Input
                            onChange={(e) => leftLogoUpload(e.target.files)}
                            name="logoOne"
                            size="lg"
                            type="file"
                            placeholder="Left side logo url"
                          />
                        )}
                      </>
                    )}
                  </InputGroup>
                </FormControl>
              </Stack>

              {/* right side logo */}
              <Stack w="full">
                <FormControl>
                  <FormLabel fontSize="16px" fontWeight="400">
                    Right side logo *
                  </FormLabel>
                  <InputGroup>
                    {rLoading ? (
                      <Spinner />
                    ) : (
                      <>
                        {rSuccess ? (
                          <Text color="green.400">Image uploaded</Text>
                        ) : (
                          <Input
                            onChange={(e) => rightLogoUpload(e.target.files)}
                            name="logoTwo"
                            size="lg"
                            type="file"
                            placeholder="Right side logo url"
                          />
                        )}
                      </>
                    )}
                  </InputGroup>
                </FormControl>
              </Stack>
            </HStack>
            <HStack
              w="full"
              justify="space-between"
              flexDir={["column", "row"]}
              spacing={[2, 5]}
            >
              {/* page title */}
              <Stack w="full">
                <FormControl>
                  <FormLabel fontSize="16px" fontWeight="400">
                    Page title
                  </FormLabel>
                  <InputGroup>
                    <Input
                      size="lg"
                      type="text"
                      placeholder="Page title"
                      onChange={(e) => setPageTitle(e.target.value)}
                    />
                  </InputGroup>
                </FormControl>
              </Stack>
              {/* page subtitle */}
              <Stack w="full">
                <FormControl>
                  <FormLabel fontSize="16px" fontWeight="400">
                    Page subtitle
                  </FormLabel>
                  <InputGroup>
                    <Input
                      onChange={(e) => setPageSubtitle(e.target.value)}
                      size="lg"
                      type="text"
                      placeholder="Page subtitle"
                    />
                  </InputGroup>
                </FormControl>
              </Stack>
            </HStack>
            <HStack
              w="full"
              justify="space-between"
              flexDir={["column", "row"]}
              spacing={[2, 5]}
            >
              {/* page name */}
              <Stack w="full">
                <FormControl>
                  <FormLabel fontSize="16px" fontWeight="400">
                    Page name
                  </FormLabel>
                  <InputGroup>
                    <Input
                      onChange={(e) => setPageName(e.target.value)}
                      size="lg"
                      type="text"
                      placeholder="Page name"
                    />
                  </InputGroup>
                </FormControl>
              </Stack>
              {/* Page purpose */}
              <Stack w="full">
                <FormControl>
                  <FormLabel fontSize="16px" fontWeight="400">
                    Page Purpose
                  </FormLabel>
                  <InputGroup>
                    <Input
                      onChange={(e) => setPagePurpose(e.target.value)}
                      size="lg"
                      type="text"
                      placeholder="Page purpose"
                    />
                  </InputGroup>
                </FormControl>
              </Stack>
            </HStack>
            <HStack
              w="full"
              justify="space-between"
              flexDir={["column", "row"]}
              spacing={[2, 5]}
            >
              {/* page desire */}
              <Stack w="full">
                <FormControl>
                  <FormLabel fontSize="16px" fontWeight="400">
                    Page desire
                  </FormLabel>
                  <InputGroup>
                    <Input
                      onChange={(e) => setPageDesire(e.target.value)}
                      size="lg"
                      type="text"
                      placeholder="Page desire"
                    />
                  </InputGroup>
                </FormControl>
              </Stack>
              {/* de more off */}
              <Stack w="full">
                <FormControl>
                  <FormLabel fontSize="16px" fontWeight="400">
                    Do more of list
                  </FormLabel>
                  <InputGroup>
                    <Textarea
                      onChange={(e) => setPageDoMoreOf(e.target.value)}
                      size="lg"
                      placeholder="Independence; Self-Awareness; Trusting of own impulses; ..."
                    />
                  </InputGroup>
                </FormControl>
              </Stack>
            </HStack>

            <HStack
              w="full"
              justify="space-between"
              flexDir={["column", "row"]}
              spacing={[2, 5]}
            >
              {/* let go off */}
              <Stack w="full">
                <FormControl>
                  <FormLabel fontSize="16px" fontWeight="400">
                    Let go off
                  </FormLabel>
                  <InputGroup>
                    <Textarea
                      onChange={(e) => setPageLetGoOf(e.target.value)}
                      size="lg"
                      placeholder="Independence; Self-Awareness; Trusting of own impulses; ..."
                    />
                  </InputGroup>
                </FormControl>
              </Stack>
              {/* challenge */}
              <Stack w="full">
                <FormControl>
                  <FormLabel fontSize="16px" fontWeight="400">
                    Challenge
                  </FormLabel>
                  <InputGroup>
                    <Textarea
                      onChange={(e) => setPageChallenge(e.target.value)}
                      size="lg"
                      placeholder="Independence; Self-Awareness; Trusting of own impulses; ..."
                    />
                  </InputGroup>
                </FormControl>
              </Stack>
            </HStack>
            <HStack
              w="full"
              justify="space-between"
              flexDir={["column", "row"]}
              spacing={[2, 5]}
            >
              {/* page desire */}
              <Stack w="full">
                <FormControl>
                  <FormLabel fontSize="16px" fontWeight="400">
                    Avoid
                  </FormLabel>
                  <InputGroup>
                    <Input
                      onChange={(e) => setPageAvoid(e.target.value)}
                      size="lg"
                      type="text"
                      placeholder="Avoid..."
                    />
                  </InputGroup>
                </FormControl>
              </Stack>
              {/* Purpose description */}
              <Stack w="full">
                <FormControl>
                  <FormLabel fontSize="16px" fontWeight="400">
                    Purpose Description
                  </FormLabel>
                  <InputGroup>
                    <Textarea
                      onChange={(e) => setPurposeDescription(e.target.value)}
                      size="lg"
                      placeholder="Avoid..."
                    />
                  </InputGroup>
                </FormControl>
              </Stack>
            </HStack>
            <HStack
              w="full"
              justify="space-between"
              flexDir={["column", "row"]}
              spacing={[2, 5]}
            >
              {/* Bottom box color */}
              <Stack w="full">
                <FormControl>
                  <FormLabel fontSize="16px" fontWeight="400">
                    Select box color
                  </FormLabel>
                  <Select
                    placeholder="Select Box color"
                    onChange={(e) => setBox_color(e.target.value)}
                  >
                    <option value="#008037">Green</option>
                    <option value="#A60E13">Red</option>
                    <option value="#F7C23A">Yellow</option>
                    <option value="#575757">Gray</option>
                    <option value="#004AAD">Blue</option>
                    <option value="#E0E9EF">Cream</option>
                    <option value="#000">Black</option>
                    <option value="orange">Orange</option>
                    <option value="#E0EAF1">Cream Blue</option>
                  </Select>
                </FormControl>
              </Stack>
            </HStack>
          </>
        ) : gridtype === "3" ? (
          <>
            <HStack
              w="full"
              justify="space-between"
              flexDir={["column", "row"]}
              spacing={[2, 5]}
            >
              {/* left side logo */}
              <Stack w="full">
                <FormControl>
                  <FormLabel fontSize="16px" fontWeight="400">
                    Left side logo *
                  </FormLabel>
                  <InputGroup>
                    {lLoading ? (
                      <Spinner />
                    ) : (
                      <>
                        {lSuccess ? (
                          <Text color="green.400">Image uploaded</Text>
                        ) : (
                          <Input
                            onChange={(e) => leftLogoUpload(e.target.files)}
                            size="lg"
                            type="file"
                            placeholder="Left side logo url"
                          />
                        )}
                      </>
                    )}
                  </InputGroup>
                </FormControl>
              </Stack>
              {/* right side logo */}
              {chart !== "CHART_TWO" ? (
                <Stack w="full">
                  <FormControl>
                    <FormLabel fontSize="16px" fontWeight="400">
                      Right side logo *
                    </FormLabel>
                    <InputGroup>
                      {rLoading ? (
                        <Spinner />
                      ) : (
                        <>
                          {rSuccess ? (
                            <Text color="green.400">Image uploaded</Text>
                          ) : (
                            <Input
                              onChange={(e) => rightLogoUpload(e.target.files)}
                              size="lg"
                              type="file"
                              placeholder="Right side logo url"
                            />
                          )}
                        </>
                      )}
                    </InputGroup>
                  </FormControl>
                </Stack>
              ) : (
                <Stack w="full">
                  <FormControl>
                    <FormLabel fontSize="16px" fontWeight="400">
                      WOUND LIST
                    </FormLabel>
                    <InputGroup>
                      <Textarea
                        onChange={(e) => setWound(e.target.value)}
                        size="lg"
                        placeholder="list one; list two; list three;"
                      />
                    </InputGroup>
                  </FormControl>
                </Stack>
              )}
            </HStack>
            <HStack
              w="full"
              justify="space-between"
              flexDir={["column", "row"]}
              spacing={[2, 5]}
            >
              {/* page title */}
              {chart !== "CHART_TWO" ? (
                <Stack w="full">
                  <FormControl>
                    <FormLabel fontSize="16px" fontWeight="400">
                      Page title
                    </FormLabel>
                    <InputGroup>
                      <Input
                        size="lg"
                        type="text"
                        placeholder="Page title"
                        onChange={(e) => setPageTitle(e.target.value)}
                      />
                    </InputGroup>
                  </FormControl>
                </Stack>
              ) : (
                <Stack w="full">
                  <FormControl>
                    <FormLabel fontSize="16px" fontWeight="400">
                      REMEDY LIST
                    </FormLabel>
                    <InputGroup>
                      <Textarea
                        onChange={(e) => setRemedy(e.target.value)}
                        size="lg"
                        placeholder="list one; list two; list three;"
                      />
                    </InputGroup>
                  </FormControl>
                </Stack>
              )}

              {/* page name */}
              <Stack w="full">
                <FormControl>
                  <FormLabel fontSize="16px" fontWeight="400">
                    Page name
                  </FormLabel>
                  <InputGroup>
                    <Input
                      onChange={(e) => setPageName(e.target.value)}
                      size="lg"
                      type="text"
                      placeholder="Page name"
                    />
                  </InputGroup>
                </FormControl>
              </Stack>
            </HStack>
            <HStack
              w="full"
              justify="space-between"
              flexDir={["column", "row"]}
              spacing={[2, 5]}
            >
              {/* Down Bold text */}
              <Stack w="full">
                <FormControl>
                  <FormLabel fontSize="16px" fontWeight="400">
                    Down Bold text
                  </FormLabel>
                  <InputGroup>
                    <Input
                      onChange={(e) => setDownBoldText(e.target.value)}
                      size="lg"
                      type="text"
                      placeholder="Down bold text"
                    />
                  </InputGroup>
                </FormControl>
              </Stack>
            </HStack>
            <HStack
              w="full"
              justify="space-between"
              flexDir={["column", "row"]}
              spacing={[2, 5]}
            >
              {/* page description */}
              {chart !== "CHART_TWO" ? (
                <Stack w="full">
                  <FormControl>
                    <FormLabel fontSize="16px" fontWeight="400">
                      Page Descriptoion
                    </FormLabel>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        marginBottom: "20px",
                      }}
                    >
                      <div ref={quillRef} />
                    </div>
                  </FormControl>
                </Stack>
              ) : (
                <>
                  {/*  box color */}
                  <Stack w="full">
                    <FormControl>
                      <FormLabel fontSize="16px" fontWeight="400">
                        Select box color
                      </FormLabel>
                      <Select
                        placeholder="Select Button Box color"
                        onChange={(e) => setBox_color(e.target.value)}
                      >
                        <option value="#008037">Green</option>
                        <option value="#A60E13">Red</option>
                        <option value="#F7C23A">Yellow</option>
                        <option value="#575757">Gray</option>
                        <option value="#004AAD">Blue</option>
                        <option value="#E0E9EF">Cream</option>
                        <option value="#000">Black</option>
                        <option value="orange">Orange</option>
                        <option value="#E0EAF1">Cream Blue</option>
                      </Select>
                    </FormControl>
                  </Stack>
                </>
              )}
            </HStack>
          </>
        ) : gridtype === "4" ? (
          <>
            <HStack
              w="full"
              justify="space-between"
              flexDir={["column", "row"]}
              spacing={[2, 5]}
            >
              {/* right side logo */}
              <Stack w="full">
                <FormControl>
                  <FormLabel fontSize="16px" fontWeight="400">
                    Right side logo *
                  </FormLabel>
                  <InputGroup>
                    {rLoading ? (
                      <Spinner />
                    ) : (
                      <>
                        {rSuccess ? (
                          <Text color="green.400">Image uploaded</Text>
                        ) : (
                          <Input
                            onChange={(e) => rightLogoUpload(e.target.files)}
                            size="lg"
                            type="file"
                            placeholder="right side logo url"
                          />
                        )}
                      </>
                    )}
                  </InputGroup>
                </FormControl>
              </Stack>
              {/* page title */}
              <Stack w="full">
                <FormControl>
                  <FormLabel fontSize="16px" fontWeight="400">
                    Page title
                  </FormLabel>
                  <InputGroup>
                    <Input
                      size="lg"
                      type="text"
                      placeholder="Page title"
                      onChange={(e) => setPageTitle(e.target.value)}
                    />
                  </InputGroup>
                </FormControl>
              </Stack>
            </HStack>
            <HStack
              w="full"
              justify="space-between"
              flexDir={["column", "row"]}
              spacing={[2, 5]}
            >
              {/* Unaligned */}
              <Stack w="full">
                <FormControl>
                  <FormLabel fontSize="16px" fontWeight="400">
                    Unaligned here
                  </FormLabel>
                  <InputGroup>
                    <Input
                      onChange={(e) => setUnaligned(e.target.value)}
                      size="lg"
                      type="text"
                      placeholder="Unaligned..."
                    />
                  </InputGroup>
                </FormControl>
              </Stack>
              {/* page name */}
              <Stack w="full">
                <FormControl>
                  <FormLabel fontSize="16px" fontWeight="400">
                    Page name
                  </FormLabel>
                  <InputGroup>
                    <Input
                      onChange={(e) => setPageName(e.target.value)}
                      size="lg"
                      type="text"
                      placeholder="Page name"
                    />
                  </InputGroup>
                </FormControl>
              </Stack>
            </HStack>
            <HStack
              w="full"
              justify="space-between"
              flexDir={["column", "row"]}
              spacing={[2, 5]}
            >
              {/* aligned */}
              <Stack w="full">
                <FormControl>
                  <FormLabel fontSize="16px" fontWeight="400">
                    Aligned here
                  </FormLabel>
                  <InputGroup>
                    <Input
                      size="lg"
                      type="text"
                      placeholder="Aligned"
                      onChange={(e) => setAligned(e.target.value)}
                    />
                  </InputGroup>
                </FormControl>
              </Stack>
              {/*  box color */}
              <Stack w="full">
                <FormControl>
                  <FormLabel fontSize="16px" fontWeight="400">
                    Select box color
                  </FormLabel>
                  <Select
                    placeholder="Select Button Box color"
                    onChange={(e) => setBox_color(e.target.value)}
                  >
                    <option value="#008037">Green</option>
                    <option value="#A60E13">Red</option>
                    <option value="#F7C23A">Yellow</option>
                    <option value="#575757">Gray</option>
                    <option value="#004AAD">Blue</option>
                    <option value="#E0E9EF">Cream</option>
                    <option value="#000">Black</option>
                    <option value="orange">Orange</option>
                    <option value="#E0EAF1">Cream Blue</option>
                  </Select>
                </FormControl>
              </Stack>
            </HStack>
            <HStack
              w="full"
              justify="space-between"
              flexDir={["column", "row"]}
              spacing={[2, 5]}
            >
              {/* page description */}
              <Stack w="full">
                <FormControl>
                  <FormLabel fontSize="16px" fontWeight="400">
                    Page Descriptoion
                  </FormLabel>
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      marginBottom: "20px",
                    }}
                  >
                    <div ref={quillRef} />
                  </div>
                </FormControl>
              </Stack>
            </HStack>
          </>
        ) : gridtype === "5" ? (
          <>
            <HStack
              w="full"
              justify="space-between"
              flexDir={["column", "row"]}
              spacing={[2, 5]}
            >
              {/* right side logo */}
              <Stack w="full">
                <FormControl>
                  <FormLabel fontSize="16px" fontWeight="400">
                    Right side logo *
                  </FormLabel>
                  <InputGroup>
                    {rLoading ? (
                      <Spinner />
                    ) : (
                      <>
                        {rSuccess ? (
                          <Text color="green.400">Image uploaded</Text>
                        ) : (
                          <Input
                            onChange={(e) => rightLogoUpload(e.target.files)}
                            size="lg"
                            type="file"
                            placeholder="right side logo url"
                          />
                        )}
                      </>
                    )}
                  </InputGroup>
                </FormControl>
              </Stack>
              {/* page title */}
              <Stack w="full">
                <FormControl>
                  <FormLabel fontSize="16px" fontWeight="400">
                    Page title
                  </FormLabel>
                  <InputGroup>
                    <Input
                      size="lg"
                      type="text"
                      placeholder="Page title"
                      onChange={(e) => setPageTitle(e.target.value)}
                    />
                  </InputGroup>
                </FormControl>
              </Stack>
            </HStack>
            <HStack
              w="full"
              justify="space-between"
              flexDir={["column", "row"]}
              spacing={[2, 5]}
            >
              {/* page name */}
              <Stack w="full">
                <FormControl>
                  <FormLabel fontSize="16px" fontWeight="400">
                    Page name
                  </FormLabel>
                  <InputGroup>
                    <Input
                      onChange={(e) => setPageName(e.target.value)}
                      size="lg"
                      type="text"
                      placeholder="Page name"
                    />
                  </InputGroup>
                </FormControl>
              </Stack>
              {/*  box color */}
              <Stack w="full">
                <FormControl>
                  <FormLabel fontSize="16px" fontWeight="400">
                    Select box color
                  </FormLabel>
                  <Select
                    placeholder="Select Button Box color"
                    onChange={(e) => setBox_color(e.target.value)}
                  >
                    <option value="#008037">Green</option>
                    <option value="#A60E13">Red</option>
                    <option value="#F7C23A">Yellow</option>
                    <option value="#575757">Gray</option>
                    <option value="#004AAD">Blue</option>
                    <option value="#E0E9EF">Cream</option>
                    <option value="#000">Black</option>
                    <option value="orange">Orange</option>
                    <option value="#E0EAF1">Cream Blue</option>
                  </Select>
                </FormControl>
              </Stack>
            </HStack>
            <HStack
              w="full"
              justify="space-between"
              flexDir={["column", "row"]}
              spacing={[2, 5]}
            >
              {/* page description */}
              <Stack w="full">
                <FormControl>
                  <FormLabel fontSize="16px" fontWeight="400">
                    Page Descriptoion
                  </FormLabel>
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      marginBottom: "20px",
                    }}
                    id="text"
                  >
                    <div ref={quillRef} />
                  </div>
                </FormControl>
              </Stack>
            </HStack>
          </>
        ) : gridtype === "6" ? (
          <>
            <HStack
              w="full"
              justify="space-between"
              flexDir={["column", "row"]}
              spacing={[2, 5]}
            >
              {/* right side logo */}
              <Stack w="full">
                <FormControl>
                  <FormLabel fontSize="16px" fontWeight="400">
                    Right side logo *
                  </FormLabel>
                  <InputGroup>
                    {rLoading ? (
                      <Spinner />
                    ) : (
                      <>
                        {rSuccess ? (
                          <Text color="green.400">Image uploaded</Text>
                        ) : (
                          <Input
                            onChange={(e) => rightLogoUpload(e.target.files)}
                            size="lg"
                            type="file"
                            placeholder="Right side logo url"
                          />
                        )}
                      </>
                    )}
                  </InputGroup>
                </FormControl>
              </Stack>
              {/* page title */}
              <Stack w="full">
                <FormControl>
                  <FormLabel fontSize="16px" fontWeight="400">
                    Page title
                  </FormLabel>
                  <InputGroup>
                    <Input
                      size="lg"
                      type="text"
                      placeholder="Page title"
                      onChange={(e) => setPageTitle(e.target.value)}
                    />
                  </InputGroup>
                </FormControl>
              </Stack>
            </HStack>

            <HStack
              w="full"
              justify="space-between"
              flexDir={["column", "row"]}
              spacing={[2, 5]}
            >
              {/* page description */}
              {chart !== "CHART_TWO" ? null : (
                <Stack w="full">
                  <FormControl>
                    <FormLabel fontSize="16px" fontWeight="400">
                      REMEDY LIST
                    </FormLabel>
                    <InputGroup>
                      <Textarea
                        onChange={(e) => setRemedy(e.target.value)}
                        size="lg"
                        placeholder="list one; list two; list three;"
                      />
                    </InputGroup>
                  </FormControl>
                </Stack>
              )}
            </HStack>
            <HStack
              w="full"
              justify="space-between"
              flexDir={["column", "row"]}
              spacing={[2, 5]}
            >
              {/* Down Bold text */}
              <Stack w="full">
                <FormControl>
                  <FormLabel fontSize="16px" fontWeight="400">
                    Down Bold text
                  </FormLabel>
                  <InputGroup>
                    <Input
                      onChange={(e) => setDownBoldText(e.target.value)}
                      size="lg"
                      type="text"
                      placeholder="Down bold text"
                    />
                  </InputGroup>
                </FormControl>
              </Stack>
              {/* page description */}
              {chart !== "CHART_TWO" ? null : (
                // <Stack w="full">
                //   <FormControl>
                //     <FormLabel fontSize="16px" fontWeight="400">
                //       CHALLENGE
                //     </FormLabel>
                //     <InputGroup>
                //       <Textarea
                //         onChange={(e) => setWound(e.target.value)}
                //         size="lg"
                //         placeholder="list one; list two; list three;"
                //       />
                //     </InputGroup>
                //   </FormControl>
                // </Stack>
                <Stack w="full">
                  <FormControl>
                    <FormLabel fontSize="16px" fontWeight="400">
                      CHALLENGE
                    </FormLabel>
                    <InputGroup>
                      <Textarea
                        onChange={(e) => setPageChallenge(e.target.value)}
                        size="lg"
                        placeholder="list one; list two; list three;"
                      />
                    </InputGroup>
                  </FormControl>
                </Stack>
              )}
              {/*  box color */}
              <Stack w="full">
                <FormControl>
                  <FormLabel fontSize="16px" fontWeight="400">
                    Select box color
                  </FormLabel>
                  <Select
                    placeholder="Select Button Box color"
                    onChange={(e) => setBox_color(e.target.value)}
                  >
                    <option value="#008037">Green</option>
                    <option value="#A60E13">Red</option>
                    <option value="#F7C23A">Yellow</option>
                    <option value="#575757">Gray</option>
                    <option value="#004AAD">Blue</option>
                    <option value="#E0E9EF">Cream</option>
                    <option value="#000">Black</option>
                    <option value="orange">Orange</option>
                    <option value="#E0EAF1">Cream Blue</option>
                  </Select>
                </FormControl>
              </Stack>
            </HStack>
            <HStack
              w="full"
              justify="space-between"
              flexDir={["column", "row"]}
              spacing={[2, 5]}
            >
              {/* page description */}
              {chart !== "CHART_TWO" ? (
                <Stack w="full">
                  <FormControl>
                    <FormLabel fontSize="16px" fontWeight="400">
                      Page Descriptoion
                    </FormLabel>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        marginBottom: "20px",
                      }}
                    >
                      <div ref={quillRef} />
                    </div>
                  </FormControl>
                </Stack>
              ) : null}
            </HStack>
          </>
        ) : gridtype === "7" ? (
          <>
            <HStack
              w="full"
              justify="space-between"
              flexDir={["column", "row"]}
              spacing={[2, 5]}
            >
              {/* right side logo */}
              <Stack w="full">
                <FormControl>
                  <FormLabel fontSize="16px" fontWeight="400">
                    Right side logo *
                  </FormLabel>
                  <InputGroup>
                    {rLoading ? (
                      <Spinner />
                    ) : (
                      <>
                        {rSuccess ? (
                          <Text color="green.400">Image uploaded</Text>
                        ) : (
                          <Input
                            onChange={(e) => rightLogoUpload(e.target.files)}
                            size="lg"
                            type="file"
                            placeholder="Right side logo url"
                          />
                        )}
                      </>
                    )}
                  </InputGroup>
                </FormControl>
              </Stack>
              {/* page title */}
              <Stack w="full">
                <FormControl>
                  <FormLabel fontSize="16px" fontWeight="400">
                    Page title
                  </FormLabel>
                  <InputGroup>
                    <Input
                      size="lg"
                      type="text"
                      placeholder="Page title"
                      onChange={(e) => setPageTitle(e.target.value)}
                    />
                  </InputGroup>
                </FormControl>
              </Stack>
            </HStack>
            <HStack
              w="full"
              justify="space-between"
              flexDir={["column", "row"]}
              spacing={[2, 5]}
            >
              {/* page name */}
              <Stack w="full">
                <FormControl>
                  <FormLabel fontSize="16px" fontWeight="400">
                    Page name
                  </FormLabel>
                  <InputGroup>
                    <Input
                      onChange={(e) => setPageName(e.target.value)}
                      size="lg"
                      type="text"
                      placeholder="Page name"
                    />
                  </InputGroup>
                </FormControl>
              </Stack>
              {/* Page purpose */}
              <Stack w="full">
                <FormControl>
                  <FormLabel fontSize="16px" fontWeight="400">
                    Page Purpose
                  </FormLabel>
                  <InputGroup>
                    <Input
                      onChange={(e) => setPagePurpose(e.target.value)}
                      size="lg"
                      type="text"
                      placeholder="Page purpose"
                    />
                  </InputGroup>
                </FormControl>
              </Stack>
            </HStack>
            <HStack
              w="full"
              justify="space-between"
              flexDir={["column", "row"]}
              spacing={[2, 5]}
            >
              {/* When unaligned */}
              <Stack w="full">
                <FormControl>
                  <FormLabel fontSize="16px" fontWeight="400">
                    When unaligned list
                  </FormLabel>
                  <InputGroup>
                    <Textarea
                      onChange={(e) => setWhenUnaligned(e.target.value)}
                      size="lg"
                      placeholder="Independence; Self-Awareness; Trusting of own impulses; ..."
                    />
                  </InputGroup>
                </FormControl>
              </Stack>
              {/* When aligned */}
              <Stack w="full">
                <FormControl>
                  <FormLabel fontSize="16px" fontWeight="400">
                    When aligned list
                  </FormLabel>
                  <InputGroup>
                    <Textarea
                      onChange={(e) => setWhenAligned(e.target.value)}
                      size="lg"
                      placeholder="Independence; Self-Awareness; Trusting of own impulses; ..."
                    />
                  </InputGroup>
                </FormControl>
              </Stack>
            </HStack>
            <HStack
              w="full"
              justify="space-between"
              flexDir={["column", "row"]}
              spacing={[2, 5]}
            >
              {/*  box color */}
              <Stack w="full">
                <FormControl>
                  <FormLabel fontSize="16px" fontWeight="400">
                    Select box color
                  </FormLabel>
                  <Select
                    placeholder="Select Box color"
                    onChange={(e) => setBox_color(e.target.value)}
                  >
                    <option value="#008037">Green</option>
                    <option value="#A60E13">Red</option>
                    <option value="#F7C23A">Yellow</option>
                    <option value="#575757">Gray</option>
                    <option value="#004AAD">Blue</option>
                    <option value="#E0E9EF">Cream</option>
                    <option value="#000">Black</option>
                    <option value="orange">Orange</option>
                    <option value="#E0EAF1">Cream Blue</option>
                  </Select>
                </FormControl>
              </Stack>
            </HStack>
            <HStack
              w="full"
              justify="space-between"
              flexDir={["column", "row"]}
              spacing={[2, 5]}
            >
              {/* page description */}
              <Stack w="full">
                <FormControl>
                  <FormLabel fontSize="16px" fontWeight="400">
                    Page Descriptoion
                  </FormLabel>
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      marginBottom: "20px",
                    }}
                  >
                    <div ref={quillRef} />
                  </div>
                </FormControl>
              </Stack>
            </HStack>
          </>
        ) : gridtype === "8" ? (
          <>
            <HStack
              w="full"
              justify="space-between"
              flexDir={["column", "row"]}
              spacing={[2, 5]}
            >
              {/* page title */}
              <Stack w="full">
                <FormControl>
                  <FormLabel fontSize="16px" fontWeight="400">
                    Page title
                  </FormLabel>
                  <InputGroup>
                    <Input
                      size="lg"
                      type="text"
                      placeholder="Page title"
                      onChange={(e) => setPageTitle(e.target.value)}
                    />
                  </InputGroup>
                </FormControl>
              </Stack>
              {/* page name */}
              <Stack w="full">
                <FormControl>
                  <FormLabel fontSize="16px" fontWeight="400">
                    Page name
                  </FormLabel>
                  <InputGroup>
                    <Input
                      onChange={(e) => setPageName(e.target.value)}
                      size="lg"
                      type="text"
                      placeholder="Page name"
                    />
                  </InputGroup>
                </FormControl>
              </Stack>
            </HStack>
            <HStack
              w="full"
              justify="space-between"
              flexDir={["column", "row"]}
              spacing={[2, 5]}
            >
              {/*  box color */}
              <Stack w="full">
                <FormControl>
                  <FormLabel fontSize="16px" fontWeight="400">
                    Select box color
                  </FormLabel>
                  <Select
                    placeholder="Select Button Box color"
                    onChange={(e) => setBox_color(e.target.value)}
                  >
                    <option value="#008037">Green</option>
                    <option value="#A60E13">Red</option>
                    <option value="#F7C23A">Yellow</option>
                    <option value="#575757">Gray</option>
                    <option value="#004AAD">Blue</option>
                    <option value="#E0E9EF">Cream</option>
                    <option value="#000">Black</option>
                    <option value="orange">Orange</option>
                    <option value="#E0EAF1">Cream Blue</option>
                  </Select>
                </FormControl>
              </Stack>
            </HStack>

            <HStack
              w="full"
              justify="space-between"
              flexDir={["column", "row"]}
              spacing={[2, 5]}
            >
              {/* page description */}
              <Stack w="full">
                <FormControl>
                  <FormLabel fontSize="16px" fontWeight="400">
                    Page Descriptoion
                  </FormLabel>
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      marginBottom: "20px",
                    }}
                  >
                    <div ref={quillRef} />
                  </div>
                </FormControl>
              </Stack>
            </HStack>
          </>
        ) : (
          <>
            <HStack
              w="full"
              justify="space-between"
              flexDir={["column", "row"]}
              spacing={[2, 5]}
            >
              {/* page title */}
              <Stack w="full">
                <FormControl>
                  <FormLabel fontSize="16px" fontWeight="400">
                    Page title
                  </FormLabel>
                  <InputGroup>
                    <Input
                      size="lg"
                      type="text"
                      placeholder="Page title"
                      onChange={(e) => setPageTitle(e.target.value)}
                    />
                  </InputGroup>
                </FormControl>
              </Stack>
              {/* page name */}
              <Stack w="full">
                <FormControl>
                  <FormLabel fontSize="16px" fontWeight="400">
                    Page name
                  </FormLabel>
                  <InputGroup>
                    <Input
                      onChange={(e) => setPageName(e.target.value)}
                      size="lg"
                      type="text"
                      placeholder="Page name"
                    />
                  </InputGroup>
                </FormControl>
              </Stack>
            </HStack>
            <HStack
              w="full"
              justify="space-between"
              flexDir={["column", "row"]}
              spacing={[2, 5]}
            >
              {/*  box color */}
              <Stack w="full">
                <FormControl>
                  <FormLabel fontSize="16px" fontWeight="400">
                    Select box color
                  </FormLabel>
                  <Select
                    placeholder="Select Button Box color"
                    onChange={(e) => setBox_color(e.target.value)}
                  >
                    <option value="#008037">Green</option>
                    <option value="#A60E13">Red</option>
                    <option value="#F7C23A">Yellow</option>
                    <option value="#575757">Gray</option>
                    <option value="#004AAD">Blue</option>
                    <option value="#E0E9EF">Cream</option>
                    <option value="#000">Black</option>
                    <option value="orange">Orange</option>
                    <option value="#E0EAF1">Cream Blue</option>
                  </Select>
                </FormControl>
              </Stack>
            </HStack>
            <HStack
              w="full"
              justify="space-between"
              flexDir={["column", "row"]}
              spacing={[2, 5]}
            >
              {/* page description */}
              <Stack w="full">
                <FormControl>
                  <FormLabel fontSize="16px" fontWeight="400">
                    Page Descriptoion
                  </FormLabel>
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      marginBottom: "20px",
                    }}
                  >
                    <div ref={quillRef} />
                  </div>
                </FormControl>
              </Stack>
            </HStack>
          </>
        )}
        <HStack justify="space-between" w="full" py={5}>
          <Button
            as={Link}
            to="/dashboard/report"
            leftIcon={<BsArrowLeftCircle />}
            fontSize="16px"
            fontWeight="400"
            rounded="6px"
          >
            Back
          </Button>
          <Button
            onClick={insertData}
            fontSize="16px"
            fontWeight="400"
            bg="#FF6633"
            color="white"
            rounded="6px"
            width="130px"
            _hover={{ bg: "#fb4509", transition: "ease 0.3s all" }}
          >
            {loading ? <Spinner /> : "Create"}
          </Button>
        </HStack>
      </VStack>
    </Flex>
  );
};

export default NewReport;
