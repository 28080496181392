import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Input,
  InputGroup,
  Select,
  Spinner,
  Stack,
  useColorMode,
  VStack,
} from "@chakra-ui/react";

import { useState } from "react";
import { toast } from "react-hot-toast";
import { BsArrowLeftCircle } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import useAxios from "../../hooks/useAxios";

const Profile = () => {
  const { session } = useAuth();
  const axios = useAxios();
  const { colorMode } = useColorMode();
  const navigate = useNavigate();
  const [role, setRole] = useState<string>("ADMIN");
  const [name, setName] = useState<string>(
    session?.user && session.user.name ? session.user.name : ""
  );
  const [email, setEmail] = useState<string>(
    session?.user && session?.user.email ? session?.user.email : ""
  );
  const [username, setUsername] = useState<string>(
    session?.user && session.user.username ? session.user.username : ""
  );
  const [password, setPassword] = useState<string>("");
  const [reType, setRetype] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const handleSubmit = async (e: any) => {
    setLoading(true);
    if (password !== reType) {
      setLoading(false);
      return toast.error("Password does not match🥺");
    }
    const id = session?.user?.uuid;
    try {
      await axios.put(`/api/users/update/${id}`, {
        name,
        email,
        username,
        role,
        password,
      });
      toast.success("Admin profile updated🙂");
      setLoading(false);
      navigate("/");
    } catch (error: any) {
      if (!error?.response) return toast.error("Server error😔");
      toast.error(error?.response?.data?.message);
      setLoading(false);
    }
  };

  return (
    <Flex
      bg={colorMode === "dark" ? "blackAlpha.300" : "white"}
      rounded="10px"
      boxShadow="0px 4px 6px rgba(94, 73, 155, 0.04)"
      flexDir="column"
      gap={6}
    >
      {/* page header */}
      <Stack
        borderBottom="1px"
        borderBottomColor="#a6aebb4d"
        w="full"
        p={[2, 5]}
      >
        <Heading fontSize="20px" fontWeight="500">
          Update Admin profile
        </Heading>
      </Stack>
      {/* from */}
      <VStack px={[2, 14]} pb={[2, 5]} spacing={[2, 4]}>
        <HStack
          w="full"
          justify="space-between"
          flexDir={["column", "row"]}
          spacing={[2, 5]}
        >
          <Stack w="full">
            <FormControl>
              <FormLabel fontSize="16px" fontWeight="400">
                Users role *
              </FormLabel>
              <Select placeholder="Select user role" disabled>
                <option value="ADMIN" selected>
                  ADMIN
                </option>
                <option value="CLIENT">COACH</option>
              </Select>
            </FormControl>
          </Stack>
          <Stack w="full">
            <FormControl>
              <FormLabel fontSize="16px" fontWeight="400">
                Full Name
              </FormLabel>
              <InputGroup>
                <Input
                  size="lg"
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Full name"
                />
              </InputGroup>
            </FormControl>
          </Stack>
        </HStack>
        <HStack
          w="full"
          justify="space-between"
          flexDir={["column", "row"]}
          spacing={[2, 5]}
        >
          <Stack w="full">
            <FormControl>
              <FormLabel fontSize="16px" fontWeight="400">
                Email
              </FormLabel>
              <InputGroup>
                <Input
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  size="lg"
                  type="text"
                  placeholder="email"
                />
              </InputGroup>
            </FormControl>
          </Stack>
          <Stack w="full">
            <FormControl>
              <FormLabel fontSize="16px" fontWeight="400">
                Username
              </FormLabel>
              <InputGroup>
                <Input
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  size="lg"
                  type="text"
                  placeholder="Username"
                />
              </InputGroup>
            </FormControl>
          </Stack>
        </HStack>
        <HStack
          w="full"
          justify="space-between"
          flexDir={["column", "row"]}
          spacing={[2, 5]}
        >
          <Stack w="full">
            <FormControl isRequired>
              <FormLabel fontSize="16px" fontWeight="400">
                Password
              </FormLabel>
              <InputGroup>
                <Input
                  onChange={(e) => setPassword(e.target.value)}
                  size="lg"
                  type="text"
                  placeholder="Password"
                />
              </InputGroup>
            </FormControl>
          </Stack>
          <Stack w="full">
            <FormControl isRequired>
              <FormLabel fontSize="16px" fontWeight="400">
                Retype password
              </FormLabel>
              <InputGroup>
                <Input
                  value={reType}
                  onChange={(e) => setRetype(e.target.value)}
                  size="lg"
                  type="text"
                  placeholder="Password"
                />
              </InputGroup>
            </FormControl>
          </Stack>
        </HStack>

        <HStack justify="space-between" w="full" py={5}>
          <Button
            as={Link}
            to="/"
            leftIcon={<BsArrowLeftCircle />}
            fontSize="16px"
            fontWeight="400"
            rounded="6px"
          >
            Back
          </Button>
          <Button
            type="submit"
            fontSize="16px"
            fontWeight="400"
            bg="#FF6633"
            color="white"
            rounded="6px"
            width="130px"
            onClick={handleSubmit}
            _hover={{ bg: "#fb4509", transition: "ease 0.3s all" }}
          >
            {loading ? <Spinner /> : "Update"}
          </Button>
        </HStack>
      </VStack>
    </Flex>
  );
};

export default Profile;
