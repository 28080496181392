import { createContext } from "react";

export interface IUserInput {
  uuid?: string;
  date: string;
  name: string;
  username?: string;
  fullNameAtBirth: string;
  dateOfBirth: string;
  timeOfBirth: string;
  town: string;
  country: string;
  charts: string;
  createdAt?: string;
  updatedAt?: string;
}

export interface IUserContextState {
  userInputs: IUserInput | undefined;
}

export const defaultContextState: IUserContextState = {
  userInputs: undefined,
};

export type TInputContextActions = "set_input" | "unset_input";

export type TInputContextPayload = IUserInput | undefined;

export interface IInputContextActions {
  type: TInputContextActions;
  payload: TInputContextPayload;
}

export const InputReducer = (
  state: IUserContextState,
  action: IInputContextActions
) => {
  switch (action.type) {
    case "set_input": {
      return { ...state, userInputs: action.payload as IUserInput };
    }
    case "unset_input": {
      return { ...state, userInputs: undefined as undefined };
    }
    default:
      return { ...state };
  }
};

interface IInputContextProps {
  inputState: IUserContextState;
  inputDispatch: React.Dispatch<IInputContextActions>;
}
const InputContext = createContext<IInputContextProps>({
  inputState: defaultContextState,
  inputDispatch: () => {},
});

export const InputContextConsumer = InputContext.Consumer;
export const InputContextProvider = InputContext.Provider;

export default InputContext;
