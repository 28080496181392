import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  Flex,
  Heading,
  Icon,
  Stack,
  useColorMode,
  useDisclosure,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { createColumnHelper } from "@tanstack/react-table";
import moment from "moment";
import { useState } from "react";
import { AiOutlineEye, AiOutlinePlusCircle } from "react-icons/ai";
import { IoIosArrowDropdown } from "react-icons/io";
import { MdDelete } from "react-icons/md";
import { Link } from "react-router-dom";
import Alerts from "../../components/common/Alert";
import Error from "../../components/common/Error";
import Loading from "../../components/common/Loading";
import Datatable from "../../components/Datatable/Datatable";
import useAxios from "../../hooks/useAxios";

interface IReport {
  chart?: string;
  GNPN: string;
  pageNumber: number;
  pageTitle: string;
  pageName: string;
  pagePurpose: string;
  aligned: string;
  createdAt: any;
  uuid: string;
}

const Report = () => {
  window.document.title = "SOUL BLUEPRINT ALIGNMENT - Reports";
  const axios = useAxios();
  const { colorMode } = useColorMode();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [id, setId] = useState<string>("");

  const { data, isLoading, isError } = useQuery({
    queryKey: ["reports"],
    queryFn: () => axios.get("/api/data/getall").then((res) => res.data),
    // onSuccess: () => {
    //   queryClients.resetQueries(["reports"]);
    // },
  });

  const deleteReport = (id: string) => {
    setId(id);
    onOpen();
  };

  const columnHelper = createColumnHelper<IReport>();
  const columns = [
    columnHelper.accessor("uuid", {
      header: () => "S.N",
      cell: (info) => info.row.index + 1,
    }),
    columnHelper.accessor("GNPN", {
      header: () => "GNPN",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("pageNumber", {
      header: () => "Page Number",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("pageTitle", {
      header: () => "Page Title",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("pageName", {
      header: () => "Page Name",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("pagePurpose", {
      header: () => "Page Purpose",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("chart", {
      header: () => "chart",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("createdAt", {
      header: () => "Created at",
      cell: (info) => moment(info.getValue()).format("LL"),
    }),

    columnHelper.display({
      header: "Actions",
      id: "actions",
      cell: (props) => (
        <ButtonGroup>
          <Button
            as={Link}
            to={`/dashboard/report/view/${props?.row.original.uuid}`}
            rounded="2xl"
            size="sm"
            fontSize="14px"
            colorScheme="green"
            leftIcon={<Icon mr={-1} as={AiOutlineEye} fontSize="16px" />}
          >
            Open
          </Button>
          <Button
            onClick={() => deleteReport(props?.row?.original?.uuid)}
            rounded="2xl"
            size="sm"
            fontSize="14px"
            colorScheme="red"
            leftIcon={<Icon mr={-1} as={MdDelete} fontSize="16px" />}
          >
            Delete
          </Button>
        </ButtonGroup>
      ),
    }),
  ];
  if (isLoading) return <Loading />;
  if (isError) return <Error message="Not able to face data!" />;
  return (
    <>
      <Alerts
        isOpen={isOpen}
        onClose={onClose}
        message="Are you sure!🤚 to delete this REPORT"
        id={id}
        type="report"
      />
      <Card
        bg={colorMode === "dark" ? "blackAlpha.300" : "white"}
        id="from accont card"
        rounded="10px"
        boxShadow="0px 4px 6px rgba(94, 73, 155, 0.04)"
      >
        <Flex
          as={CardHeader}
          align="center"
          justify="space-between"
          borderBottom="0.5px solid #BFBFBF"
        >
          <Heading fontWeight={500} fontSize="20px">
            List of the report
          </Heading>
          <Stack direction="row" spacing={4}>
            <Button
              color="white"
              bg="#2C3A4B"
              _hover={{ bg: "#2C3A4B" }}
              rightIcon={<Icon as={IoIosArrowDropdown} />}
            >
              Export Report
            </Button>
            <Button
              as={Link}
              to="/dashboard/report/create"
              bg="primary.200"
              color="white"
              colorScheme="yellow"
              leftIcon={<Icon as={AiOutlinePlusCircle} />}
            >
              Insert report in Database
            </Button>
          </Stack>
        </Flex>

        <CardBody>
          <Datatable data={data} columns={columns} />
        </CardBody>
      </Card>
    </>
  );
};

export default Report;
