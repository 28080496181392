import { Box, Flex, Heading, HStack, Image, VStack } from "@chakra-ui/react";
import Copyright from "../../../components/Sm/Copyright";
import Grid from "../../../components/Sm/Grid";

interface IReport {
  item?: any;
}

const Report5: React.FC<IReport> = ({ item }) => {
  return (
    <Box
      h="auto"
      className="page-break"
      // h="1050px"
      overflow="hidden"
      w="full"
      position="relative"
    >
      <HStack align="center" justify="center" w="full" pos="relative">
        <Copyright />
        <Grid text={item?.GNPN} />
      </HStack>
      <Flex justify="space-between" align="center" pt={[2, 4]}>
        <VStack textAlign="left" align="left">
          <HStack spacing={[2, 3]} align="center">
            <Box
              w={["10", "14"]}
              h={["10", "14"]}
              bg={`${item?.box_color}`}
            ></Box>
            <Heading fontSize={["16px", "25px"]} textTransform="uppercase">
              {item?.pageTitle}
            </Heading>
          </HStack>
          <Heading
            fontSize={["17px", "20px"]}
            pt={[2, 3]}
            pb={[1, 2]}
            textTransform="uppercase"
          >
            {item?.pageName}
          </Heading>
        </VStack>
        <Image
          w={["14", "20"]}
          h={["14", "20"]}
          // src={`/uploads/${item?.logoOne ? item?.logoOne : item?.logoTwo}`}
          src={`/uploads/${item?.logoTwo}`}
          alt="logo"
        />
      </Flex>
      <VStack pt={[3, 5]}>
        <div
          style={{ width: "100%", marginTop: "15px", marginBottom: "15px" }}
          dangerouslySetInnerHTML={{ __html: item?.pageDescription }}
        ></div>
      </VStack>
    </Box>
  );
};

export default Report5;
