import { createContext } from "react";

export interface IGridData {
  reportData?: any;
}

export interface IGridDataContextState {
  gridData: IGridData | undefined;
}

export const defaultContextState: IGridDataContextState = {
  gridData: undefined,
};

export type TGridDataContextActions = "set_grid" | "unset_grid";

export type TGridDataContextPayload = IGridData | undefined;

export interface IGridDataContextActions {
  type: TGridDataContextActions;
  payload: TGridDataContextPayload;
}

export const GridDataReducer = (
  state: IGridDataContextState,
  action: IGridDataContextActions
) => {
  switch (action.type) {
    case "set_grid": {
      return { ...state, gridData: action.payload as IGridData };
    }
    case "unset_grid": {
      return { ...state, gridData: undefined as undefined };
    }
    default:
      return { ...state };
  }
};

interface IGridDataContextProps {
  gridState: IGridDataContextState;
  gridDispatch: React.Dispatch<IGridDataContextActions>;
}
const GridDataContext = createContext<IGridDataContextProps>({
  gridState: defaultContextState,
  gridDispatch: () => {},
});

export const GridDataContextConsumer = GridDataContext.Consumer;
export const GridDataContextProvider = GridDataContext.Provider;

export default GridDataContext;
