import {
  Flex,
  Grid,
  HStack,
  Icon,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import React from "react";
import CountUp from "react-countup";
import { BiRightArrow } from "react-icons/bi";
import { Link } from "react-router-dom";
import useAxios from "../hooks/useAxios";

const Dashboard = () => {
  window.document.title = "SOUL BLUEPRINT ALIGNMENT - Dashboard";
  const axios = useAxios();

  const { data: report } = useQuery({
    queryKey: ["reports"],
    queryFn: () => axios.get("/api/data/getall").then((res) => res.data),
  });
  const { data: users } = useQuery({
    queryKey: ["users"],
    queryFn: () => axios.get("/api/users").then((res) => res.data),
  });
  const { data: chart } = useQuery({
    queryKey: ["chart_data"],
    queryFn: () => axios.get("/api/chart-data").then((res) => res.data),
  });
  return (
    <Stack>
      <Text as="h1" fontSize="16px" fontWeight="500" pb="3">
        Recent summary
      </Text>
      <Grid
        gap={5}
        templateColumns={["repeat(1, 1fr)", "repeat(2, 1fr)", "repeat(4, 1fr)"]}
      >
        <Items
          title="Total users"
          name="User"
          link="/dashboard/users"
          ammout={users && (users?.length as number)}
          bg="linear-gradient(100.12deg, #05933A 4.73%, #0DB14B 102.11%)"
        />
        <Items
          link="/dashboard/report"
          title="Total Reports"
          name={"Reports"}
          ammout={report && (report?.length as number)}
          bg="linear-gradient(100.9deg, #1929BB 4.71%, #3F4ED4 99.88%)"
        />
        <Items
          link="/dashboard/chart-data"
          title="Total Chart Data"
          name="Data"
          ammout={chart && (chart?.length as number)}
          bg="linear-gradient(100.43deg, #E2104F 1.38%, #FF447C 99.88%)"
        />
      </Grid>
    </Stack>
  );
};

export default Dashboard;

interface IItemsProps {
  title: string;
  name: string;
  ammout: number;
  bg: string;
  link: string;
}
const Items: React.FC<IItemsProps> = ({ title, name, ammout, bg, link }) => {
  return (
    <Flex
      w="264px"
      h="120px"
      rounded="12px"
      bg={bg}
      p={4}
      flexDir="column"
      justify="space-between"
      color="white"
    >
      <Text
        fontSize="16px"
        fontWeight="400"
        letterSpacing="1.1%"
        color="white"
        lineHeight="24px"
      >
        {title}
      </Text>
      <HStack justify="space-between" align="end">
        <VStack spacing={0} align="left">
          <Text fontSize="10px" fontWeight="400">
            {name}
          </Text>
          <CountUp
            style={{ fontSize: "30px", fontWeight: "500", lineHeight: "32px" }}
            end={ammout}
            start={0}
            duration={2.75}
            decimals={0}
            decimal=","
          />
        </VStack>
        <Link to={link}>
          <Icon as={BiRightArrow} />
        </Link>
      </HStack>
    </Flex>
  );
};
