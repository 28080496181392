import {
  Button,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Text,
} from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React from "react";
import { toast } from "react-hot-toast";
import useAxios from "../../hooks/useAxios";

interface IAlert {
  isOpen?: any;
  onOpen?: any;
  onClose?: any;
  message: string;
  id?: string | number;
  type: string;
}

const Alerts: React.FC<IAlert> = ({
  onOpen,
  isOpen,
  onClose,
  message,
  id,
  type,
}) => {
  const cancelRef = React.useRef();
  const axios = useAxios();
  const queryClient = useQueryClient();

  const userMutaion = useMutation({
    mutationFn: () => axios.delete(`/api/users/delete/${id}`),
    onSuccess: (res) => {
      toast.success(res?.data?.message);
      queryClient.resetQueries({ queryKey: ["users"] });
    },
    onError: () => toast.error("Delete fails"),
  });

  const reportMutaion = useMutation({
    mutationFn: () => axios.delete(`/api/data/delete/${id}`),
    onSuccess: (res) => {
      toast.success(res?.data?.message);
      queryClient.resetQueries({ queryKey: ["reports"] });
    },
    onError: () => toast.error("Delete fails"),
  });

  const dataMutaion = useMutation({
    mutationFn: () => axios.delete(`/api/chart-data/delete/${id}`),
    onSuccess: (res) => {
      toast.success(res?.data?.message);
      queryClient.resetQueries({ queryKey: ["chart_data"] });
    },
    onError: () => toast.error("Delete fails"),
  });

  return (
    <>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef as any}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Customer
            </AlertDialogHeader>

            <AlertDialogBody>{message}</AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef as any} onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={onClose} ml={3}>
                <Text
                  onClick={() =>
                    type === "user"
                      ? userMutaion.mutate()
                      : type === "report"
                      ? reportMutaion.mutate()
                      : type === "chart_data"
                      ? dataMutaion.mutate()
                      : null
                  }
                >
                  Delete
                </Text>
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default Alerts;
