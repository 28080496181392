import { useContext } from "react";
import AuthContext, { Session } from "../context/AuthContext/AuthContext";

const useAuth = () => {
  const { authState, authDispatch } = useContext(AuthContext);

  const setAuth = (session: Session) => {
    authDispatch({ type: "set_auth", payload: session });
  };

  const unsetAuth = () => {
    authDispatch({ type: "unset_auth", payload: undefined });
  };

  return {
    session: authState.session,
    setAuth,
    unsetAuth,
  };
};

export default useAuth;
