import React, { PropsWithChildren, useReducer } from "react";

import {
  NumberContextProvider,
  NumberReducer,
  defaultContextState,
} from "./NumberContext";

export interface INumberContextComponentProps extends PropsWithChildren {}

const NumberContextComponent: React.FC<INumberContextComponentProps> = ({
  children,
}) => {
  const [numberState, numberDispatch] = useReducer(
    NumberReducer,
    defaultContextState
  );

  return (
    <NumberContextProvider value={{ numberState, numberDispatch }}>
      {children}
    </NumberContextProvider>
  );
};

export default NumberContextComponent;
