import { Input, Text } from "@chakra-ui/react";
import React from "react";

interface ISmNumber {
  top?: number;
  left?: number;
  right?: number;
  bottom?: number;
  number?: number | string;
  fontSize?: number;
  fontWeight?: string;
}
const SmNumber: React.FC<ISmNumber> = ({
  top,
  left,
  right,
  bottom,
  number,
  fontSize,
  fontWeight,
}) => {
  return (
    <>
      <Text
        w="30px"
        fontWeight={fontWeight ? `${fontWeight}` : "semibold"}
        fontSize={fontSize ? `${fontSize}px` : "18px"}
        position="absolute"
        textAlign="center"
        top={`${top}px`}
        left={`${left}px`}
        right={`${right}px`}
        bottom={`${bottom}`}
      >
        {number}
      </Text>
    </>
  );
};

export default SmNumber;

export const InputDisplayNumber: React.FC<ISmNumber> = ({
  top,
  left,
  right,
  bottom,
  number,
  fontSize,
  fontWeight,
}) => {
  return (
    <Input
      fontWeight={"semibold"}
      fontSize="12px"
      position="absolute"
      textAlign="center"
      border="1px"
      borderColor="gray.500"
      type="number"
      p={0}
      m={0}
      w="18px"
      h="18px"
      value={number}
      borderRadius="full"
      top={`${top}px`}
      left={`${left}px`}
      right={`${right}px`}
      bottom={`${bottom}`}
    />
  );
};
