import axios from "axios";

// const baseURL = "http://localhost:5000";
// export const newUrl = "https://server.craftedabc.store";
export const axiosPublic = axios.create({
  // baseURL: baseURL,
  // baseURL: newUrl,
  withCredentials: true,
});

export const axiosPrivate = axios.create({
  // baseURL: baseURL,
  // baseURL: newUrl,
  withCredentials: true,
});

export const uploadAxios = axios.create();
